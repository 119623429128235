import React from 'react';
import Island from '../../shared/controls/Island/Island';

type Props = {};

const ErrorNotFound: React.FC<Props> = () => {
    return (
        <Island title="Pagina niet gevonden!"/>
    );
};

export default ErrorNotFound;
