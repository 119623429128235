import { AwsRumProvider } from 'aws-rum-react';

import './utils/polyfills';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'react-router';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import IconSet from './Iconset';

import './i18n';

import { Provider } from 'react-redux';
import { LoadingSplash } from './components/shared/LoadingSplash';
import {
  createStore,
  initializePersistor,
} from './redux/store/reduxStoreFactory';
import { sagaMiddleWare } from './redux/middleware/middlewareFactory';
import rootSaga from './redux/saga';
import { renderRoutes } from 'react-router-config';
import routes from './routing/appRoutes';
import {
  initialize as initializeHistory,
  getBrowserHistory,
} from './utils/history';

import { PersistGate } from 'redux-persist/lib/integration/react';


export const rootElement = document.getElementById('root');

library.add(...IconSet);

export const reduxStore = createStore();

initializeHistory();
export const browserHistory = getBrowserHistory();

sagaMiddleWare.run(rootSaga);

const RUMDATA: Record<string, Record<string, any>> = {
  'scope.weijbv.nl': {
    id: 'ab5d6b9a-585c-4b9c-b978-3686e8c89bed',
    guestRoleArn:
      'arn:aws:iam::705999835368:role/RUM-Monitor-eu-west-1-705999835368-1744229245861-Unauth',
    identityPoolId: 'eu-west-1:0e16025c-05a4-41d4-8cf7-1a7ef3d712cc',
  },
  'localhost:4001': {
    id: 'ab5d6b9a-585c-4b9c-b978-3686e8c89bed',
    guestRoleArn:
      'arn:aws:iam::705999835368:role/RUM-Monitor-eu-west-1-705999835368-1744229245861-Unauth',
    identityPoolId: 'eu-west-1:0e16025c-05a4-41d4-8cf7-1a7ef3d712cc',
  },
  'scope.handigekasten.nl': {
    id: 'c1ca28e0-982c-4849-8588-b1305199d192',
    guestRoleArn:
      'arn:aws:iam::705999835368:role/RUM-Monitor-eu-west-1-705999835368-0393225927071-Unauth',
    identityPoolId: 'eu-west-1:d3c6f035-4196-46d3-938c-67738a880605',
  },
};

let loadedRUMSettings: Record<string, any> | undefined = undefined;
if (RUMDATA[location.host.toLowerCase()]) {
  loadedRUMSettings = RUMDATA[location.host.toLowerCase()];
}

ReactDOM.render(
  <AwsRumProvider
    enableRumClient={
      loadedRUMSettings !== undefined && location.hostname !== 'localhost'
    }
    id={loadedRUMSettings ? loadedRUMSettings['id'] : ''}
    region="eu-west-1"
    version="1.0.0"
    allowCookies
    telemetries={['http', 'performance', 'errors']}
    sessionSampleRate={1}
    // guestRoleArn="arn:aws:iam::705999835368:role/RUM-Monitor-eu-west-1-705999835368-1744229245861-Unauth"
    // identityPoolId="eu-west-1:0e16025c-05a4-41d4-8cf7-1a7ef3d712cc"
    endpoint="https://dataplane.rum.eu-west-1.amazonaws.com"
    pagesToExclude={[new RegExp('/messaging/consultation/o/d+/')]}
    {...loadedRUMSettings}
  >
    <PersistGate
      loading={<LoadingSplash />}
      persistor={initializePersistor(reduxStore)}
    >
      <Provider store={reduxStore}>
        <BrowserRouter>
          <Suspense fallback={<LoadingSplash />}>
            <Router history={browserHistory}>{renderRoutes(routes)}</Router>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </PersistGate>
  </AwsRumProvider>
,
  rootElement,
);
