import { History, createBrowserHistory } from 'history';

let history: History | undefined;

export function initialize() {
    history = createBrowserHistory();
}

export function getBrowserHistory(): History {
    if (!history) {
        throw new Error(
            'Call initialize() first to intialize the browser History'
        );
    }

    return history;
}
