import { FC, SyntheticEvent, useState, useContext } from 'react';
import { ApplicationStateContext } from '../../../../context/ApplicationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../hooks/useAuth';
import Logo from '../Logo/Logo';
import { useHistory } from 'react-router';
import {
  createSearchPath,
  createNotificationsPath,
} from '../../../../routing/appUrlGenerator';
import { Configuration, MessagingApi } from '../../../../api/messaging';
import { useOnMount } from '../../../../hooks/useUpdate';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { TaskPickupForm } from './TaskPickupForm';
import { useTask } from '../../../../hooks/useTask';
import SearchBar from './SearchBar';

interface Props {
  onToggleMenu: (event: SyntheticEvent) => void;
  onChangeLanguageButtonClick: (event: SyntheticEvent) => void;
  onLogoutButtonClick: (event: SyntheticEvent) => void;
}

const TopBar: FC<Props> = ({
  onToggleMenu,
  onChangeLanguageButtonClick,
  onLogoutButtonClick,
}) => {
  const { t } = useTranslation();
  const appctx = useContext(ApplicationStateContext);
  const { auth, isNotACustomer, isCustomerUser, userDataIsLoading, isExternal } = useAuth();
  const [messageCount, setMessageCount] = useState<number>(0);

  const [searchInputFocus, setSearchInputFocus] = useState<boolean>(false);

  const [showTaskOverlay, setShowTaskOverlay] = useState<boolean>(false);
  const history = useHistory();

  const { endTask } = useTask();

  useOnMount(() => {
    fetchMessageCount();
  }, []);

  const fetchMessageCount: () => Promise<any> = async () => {
    let messagingApi = new MessagingApi(
      new Configuration({
        basePath: process.env.REACT_APP_SERVICE_URL_MESSAGING,
        accessToken: auth?.jwt,
      }),
    );

    let count = await messagingApi.getMessageCount();

    if (count?.status === 200) {
      setMessageCount(count.data);
    }
  };

  function onSearchInputFocusChange(value) {
    setSearchInputFocus(value);
  }

  const dialogFooter = (
    <div>
      <Button
        label={t('cancel')}
        icon="pi pi-times"
        onClick={() => {
          showPickupTaskOverlay(false);
        }}
      />
    </div>
  );

  return (
    <>
      <div className="layout-topbar clearfix">
        {!userDataIsLoading &&
          (!isCustomerUser() ? (
            <button
              className="p-link layout-menu-button"
              onClick={onToggleMenu}
            >
              <span className="pi pi-bars" />
            </button>
          ) : (
            <Logo />
          ))}

        {!isCustomerUser() && !isExternal() && (
          <div className="layout-topbar-search">
            <SearchBar onSearchInputFocusChange={onSearchInputFocusChange} />
          </div>
        )}

        <div className="layout-topbar-icons">
          {!searchInputFocus && (
            <>
              {isNotACustomer() && (
                <>
                  <button
                    className="p-link"
                    onClick={() => {
                      setMessageCount(0);
                      history.push(createNotificationsPath());
                    }}
                  >
                    <span className="layout-topbar-item-text">
                      {t('common:messages')}
                    </span>
                    <span className="layout-topbar-icon">
                      <FontAwesomeIcon icon="bell" className="" />
                      {messageCount > 0 && (
                        <span className="layout-topbar-badge">
                          {messageCount}
                        </span>
                      )}
                    </span>
                  </button>

                  {appctx && !appctx.taskActive && (
                    <button
                      className="p-link"
                      onClick={() => showPickupTaskOverlay(true)}
                    >
                      <span className="layout-topbar-item-text">
                        {t('common:tasks.pickup')}
                      </span>
                      <span className="layout-topbar-icon">
                        <FontAwesomeIcon icon="clock" />
                      </span>
                    </button>
                  )}
                  {appctx && appctx.taskActive && (
                    <button className="p-link" onClick={() => endTask()}>
                      <span className="layout-topbar-item-text">
                        {t('common:tasks.pickup')}
                      </span>
                      <span className="layout-topbar-icon">
                        <FontAwesomeIcon
                          style={{ color: 'red' }}
                          icon="clock"
                        />
                      </span>
                    </button>
                  )}
                </>
              )}

              <button className="p-link" onClick={onChangeLanguageButtonClick}>
                <span className="layout-topbar-item-text">
                  {t('common:changeLanguage')}
                </span>
                <span className="layout-topbar-icon">
                  <FontAwesomeIcon icon="language" />
                </span>
              </button>
            </>
          )}

          {auth?.isLoggedIn && (
            <button className="p-link" onClick={onLogoutButtonClick}>
              <span className="layout-topbar-item-text">
                {t('common:logout')}
              </span>
              <span className="layout-topbar-icon pi pi-power-off" />
            </button>
          )}
        </div>
      </div>
      <Dialog
        className="w-10"
        modal={true}
        footer={dialogFooter}
        showHeader={false}
        visible={showTaskOverlay}
        focusOnShow={true}
        onHide={() => {
          showPickupTaskOverlay(false);
        }}
        id="ovlChooseTask"
      >
        <TaskPickupForm
          onTaskPickedUp={() => {
            showPickupTaskOverlay(false);
          }}
        />
      </Dialog>
    </>
  );

  function showPickupTaskOverlay(show: boolean): void {
    if (show) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'inherit';
    }
    setShowTaskOverlay(show);
  }
};

export default TopBar;
