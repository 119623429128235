/* tslint:disable */
/* eslint-disable */
/**
 * TMS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: contact@get-scope.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DriverStatsItem
 */
export interface DriverStatsItem {
    /**
     * 
     * @type {string}
     * @memberof DriverStatsItem
     */
    'driverNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof DriverStatsItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DriverStatsItem
     */
    'telmobile'?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface JobItem
 */
export interface JobItem {
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'type'?: JobItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'targetDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'lon'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'lat'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItem
     */
    'objectNo'?: string;
}

export const JobItemTypeEnum = {
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    Service: 'Service'
} as const;

export type JobItemTypeEnum = typeof JobItemTypeEnum[keyof typeof JobItemTypeEnum];

/**
 * 
 * @export
 * @interface JobItemReq
 */
export interface JobItemReq {
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'type'?: JobItemReqTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'targetDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'lon'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'lat'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobItemReq
     */
    'objectNo'?: string;
}

export const JobItemReqTypeEnum = {
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    Service: 'Service'
} as const;

export type JobItemReqTypeEnum = typeof JobItemReqTypeEnum[keyof typeof JobItemReqTypeEnum];

/**
 * 
 * @export
 * @interface JobStatsItem
 */
export interface JobStatsItem {
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'type'?: JobStatsItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'targetDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'lon'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'lat'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'objectNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'destination'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'jobstate'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'PTA'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatsItem
     */
    'ETA'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobStatsItem
     */
    'delayed'?: boolean;
}

export const JobStatsItemTypeEnum = {
    Pickup: 'Pickup',
    Delivery: 'Delivery',
    Service: 'Service'
} as const;

export type JobStatsItemTypeEnum = typeof JobStatsItemTypeEnum[keyof typeof JobStatsItemTypeEnum];

/**
 * 
 * @export
 * @interface MutationResult
 */
export interface MutationResult {
    /**
     * 
     * @type {boolean}
     * @memberof MutationResult
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof MutationResult
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface VehicleStatsItem
 */
export interface VehicleStatsItem {
    /**
     * vehicle number
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'vehicleNo': string;
    
    vehicleDesc?: string;
    /**
     * vehicle license plate number
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'licenseplateNumber': string;
    /**
     * current vehicle odometer in meters
     * @type {number}
     * @memberof VehicleStatsItem
     */
    'odometer'?: number;
    /**
     * Name of current driver
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'driver'?: string;
    /**
     * Identifier for current driver
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'driverNo'?: string;
    /**
     * Latitude of the vehicles current location
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'lat'?: string;
    /**
     * Longtitude of the vehicles current location
     * @type {string}
     * @memberof VehicleStatsItem
     */
    'lon'?: string;
}

/**
 * TMSApi - axios parameter creator
 * @export
 */
export const TMSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes jobs in the TMS system
         * @summary Delete jobs from the TMS system
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJobs: async (requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of driver statistics
         * @summary Returns a list of driver statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDriverStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/drivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of jobs with statistics
         * @summary Returns a list of jobs with statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs: async (periodStart?: string, periodEnd?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodStart !== undefined) {
                localVarQueryParameter['periodStart'] = (periodStart as any instanceof Date) ?
                    (periodStart as any).toISOString() :
                    periodStart;
            }

            if (periodEnd !== undefined) {
                localVarQueryParameter['periodEnd'] = (periodEnd as any instanceof Date) ?
                    (periodEnd as any).toISOString() :
                    periodEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of vehicle statistics
         * @summary Returns a list of vehicle statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicleStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update jobs in the TMS system
         * @summary Upload modifications to jobs to the TMS system
         * @param {Array<JobItemReq>} [jobItemReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyJobs: async (jobItemReq?: Array<JobItemReq>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobItemReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches Status Page URL from the TMS system
         * @summary Fetches Status Page URL from the TMS system
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scopeTmsJobJobIdStatusUrlGet: async (jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('scopeTmsJobJobIdStatusUrlGet', 'jobId', jobId)
            const localVarPath = `/scope-tms/job/{jobId}/statusUrl`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace jobs in the TMS system
         * @summary Upload jobs to the TMS system
         * @param {Array<JobItem>} [jobItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJobs: async (jobItem?: Array<JobItem>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scope-tms/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TMSApi - functional programming interface
 * @export
 */
export const TMSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TMSApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes jobs in the TMS system
         * @summary Delete jobs from the TMS system
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelJobs(requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelJobs(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.cancelJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetch a list of driver statistics
         * @summary Returns a list of driver statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDriverStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<DriverStatsItem>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDriverStats(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.listDriverStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetch a list of jobs with statistics
         * @summary Returns a list of jobs with statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJobs(periodStart?: string, periodEnd?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobStatsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJobs(periodStart, periodEnd, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.listJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetch a list of vehicle statistics
         * @summary Returns a list of vehicle statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVehicleStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleStatsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVehicleStats(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.listVehicleStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * update jobs in the TMS system
         * @summary Upload modifications to jobs to the TMS system
         * @param {Array<JobItemReq>} [jobItemReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyJobs(jobItemReq?: Array<JobItemReq>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyJobs(jobItemReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.modifyJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches Status Page URL from the TMS system
         * @summary Fetches Status Page URL from the TMS system
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scopeTmsJobJobIdStatusUrlGet(jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scopeTmsJobJobIdStatusUrlGet(jobId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.scopeTmsJobJobIdStatusUrlGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Create or replace jobs in the TMS system
         * @summary Upload jobs to the TMS system
         * @param {Array<JobItem>} [jobItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadJobs(jobItem?: Array<JobItem>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadJobs(jobItem, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TMSApi.uploadJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TMSApi - factory interface
 * @export
 */
export const TMSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TMSApiFp(configuration)
    return {
        /**
         * Removes jobs in the TMS system
         * @summary Delete jobs from the TMS system
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJobs(requestBody?: Array<string>, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.cancelJobs(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of driver statistics
         * @summary Returns a list of driver statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDriverStats(options?: any): AxiosPromise<Array<Array<DriverStatsItem>>> {
            return localVarFp.listDriverStats(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of jobs with statistics
         * @summary Returns a list of jobs with statistics
         * @param {string} [periodStart] 
         * @param {string} [periodEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(periodStart?: string, periodEnd?: string, options?: any): AxiosPromise<Array<JobStatsItem>> {
            return localVarFp.listJobs(periodStart, periodEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of vehicle statistics
         * @summary Returns a list of vehicle statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVehicleStats(options?: any): AxiosPromise<Array<VehicleStatsItem>> {
            return localVarFp.listVehicleStats(options).then((request) => request(axios, basePath));
        },
        /**
         * update jobs in the TMS system
         * @summary Upload modifications to jobs to the TMS system
         * @param {Array<JobItemReq>} [jobItemReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyJobs(jobItemReq?: Array<JobItemReq>, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.modifyJobs(jobItemReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches Status Page URL from the TMS system
         * @summary Fetches Status Page URL from the TMS system
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scopeTmsJobJobIdStatusUrlGet(jobId: string, options?: any): AxiosPromise<string> {
            return localVarFp.scopeTmsJobJobIdStatusUrlGet(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace jobs in the TMS system
         * @summary Upload jobs to the TMS system
         * @param {Array<JobItem>} [jobItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJobs(jobItem?: Array<JobItem>, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.uploadJobs(jobItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TMSApi - object-oriented interface
 * @export
 * @class TMSApi
 * @extends {BaseAPI}
 */
export class TMSApi extends BaseAPI {
    /**
     * Removes jobs in the TMS system
     * @summary Delete jobs from the TMS system
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public cancelJobs(requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).cancelJobs(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of driver statistics
     * @summary Returns a list of driver statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public listDriverStats(options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).listDriverStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of jobs with statistics
     * @summary Returns a list of jobs with statistics
     * @param {string} [periodStart] 
     * @param {string} [periodEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public listJobs(periodStart?: string, periodEnd?: string, options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).listJobs(periodStart, periodEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of vehicle statistics
     * @summary Returns a list of vehicle statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public listVehicleStats(options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).listVehicleStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update jobs in the TMS system
     * @summary Upload modifications to jobs to the TMS system
     * @param {Array<JobItemReq>} [jobItemReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public modifyJobs(jobItemReq?: Array<JobItemReq>, options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).modifyJobs(jobItemReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches Status Page URL from the TMS system
     * @summary Fetches Status Page URL from the TMS system
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public scopeTmsJobJobIdStatusUrlGet(jobId: string, options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).scopeTmsJobJobIdStatusUrlGet(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace jobs in the TMS system
     * @summary Upload jobs to the TMS system
     * @param {Array<JobItem>} [jobItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TMSApi
     */
    public uploadJobs(jobItem?: Array<JobItem>, options?: RawAxiosRequestConfig) {
        return TMSApiFp(this.configuration).uploadJobs(jobItem, options).then((request) => request(this.axios, this.basePath));
    }
}



