import { Store as ReduxStore, legacy_createStore as createReduxStore } from 'redux';
import { createRootReducer } from '../reducer/rootReducerFactory';
import { createMiddlewareChain } from '../middleware/middlewareFactory';
import { GlobalState } from '../../interfaces/redux/GlobalState';
import { Action } from 'redux-act';
import {
    persistStore,
    persistCombineReducers,
} from 'redux-persist';
import storage from 'localforage';

export type Store = ReduxStore<GlobalState, Action<any>>;

export function createStore() {
    return createReduxStore(
        persistCombineReducers(
            {
                version: 0,
                key: 'scope',
                storage,
                whitelist: [
                    'auth'
                ]
            },
            createRootReducer()
        ),
        createMiddlewareChain()
    );
}

export const initializePersistor = (store: any) => persistStore(store);
