import { Configuration } from '../api/shared/configuration';
import {
    createAddFormBuilderSpecAction,
    createAddFormSummaryAction,
    createDeleteFormAction,
    createDeleteFormBuilderSpecFieldAction,
    createGetFormsAction,
    createMoveFormBuilderSpecFieldAction,
    createResetFormBuilderAction,
    createSetFormBuilderAction,
    createUpdateFormBuilderSpecAction,
    createUpdateFormBuilderSpecMetaDataAction,
    createUpdateFormSummaryAction
    } from '../redux/action/formActionFactory';
import { createFormsPath } from '../routing/appUrlGenerator';
import { CUSTOMER_USER, FACTORY_USER, OFFICE_USER } from './useAuth';
import {
    differenceInSeconds,
    endOfToday,
    getTime,
    startOfToday
    } from 'date-fns';
import { FormApi, FormCreate, FormDetails } from '../api/forms';
import {
    FormField,
    FormFieldType,
    FormMetaData,
    ISortable
    } from '../components/shared/formbuilder/utils/interfaces';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { showErrorNotification, showNotification } from '../utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

export interface FormValue {
    name: string;
    description: string;
    value: any;
}

const CACHE_TIME_IN_SECONDS = 30;

export function useForms() {
    const dispatch = useDispatch();
    const auth = useSelector((state: GlobalState) => state.auth);
    const [formDetail, setFormDetail] = useState<FormDetails | null>(null);
    const formApi = new FormApi(new Configuration({
        basePath: process.env.REACT_APP_SERVICE_URL_FORM,
        accessToken: auth?.jwt,
    }));
    const form = useSelector((state: GlobalState) => state.form);
    const { t } = useTranslation(['formbuilder']);
    const history = useHistory();

    async function getFormById(formId: string) {
        let formDetails: FormDetails | undefined;
        const response = await formApi.getFormById(formId);
        formDetails = response.data;

        if (formDetails) {
            dispatch(createSetFormBuilderAction(formDetails));
            setFormDetail(formDetails);
        }
    }

    async function getForms(ignoreCache = false) {
        if (!ignoreCache && form.updatedAt && differenceInSeconds(getTime(new Date()), form.updatedAt) <= CACHE_TIME_IN_SECONDS) {
            return;
        }

        try {
            const response = await formApi.listForms();

            let allForms = response.data;
            // add hardcoded forms
            
            // - transport
            allForms.push({
                id: 'transport',
                name: 'Transport (built-in)',
                tenantId: auth.user['custom:tenant_id'], // LELIJK!!!!!
                version: 1,
                description: '(hard coded) transport'
            })
            response.data.sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 })

            dispatch(createGetFormsAction(response.data));
        } catch (error) {
        }
    }

    async function deleteFormById(formId: string) {
        await formApi.deleteFormId(formId);
        dispatch(createDeleteFormAction({
            formId
        }));

        showNotification(
            t('statusmessage:deletesuccessful').replace('__replace__', t('form'))
        );
    }

    function getEmptyFieldValue(type: FormFieldType) {
        switch (type) {
            case 'checkboxes':
            case 'imageupload':
                return [];
            case 'rating':
                return 0;
            default:
                return '';
        }
    }

    function getEmptyField(type: FormFieldType) {
        const id = nanoid(9); //uuid();
        const values: FormField = {
            type,
            label: t('toolbaritem.placeholderlabel'),
            name: `${type}_${id}`,
            mutableBy: [FACTORY_USER, OFFICE_USER],
            visibleFor: [FACTORY_USER, OFFICE_USER, CUSTOMER_USER],
            value: getEmptyFieldValue(type),
            validation: {
                type: 'string',
                requiredFor: [],
                isRequired: false
            },
            indexing: {
                shouldIndex: false,
                indexKey: ""
            }
        };

        if (type === 'radiobuttons' || type === 'checkboxes' || type === 'select') {
            values.options = [{
                value: '0',
                text: t('toolbaritem.placeholderoption')
            }, {
                value: '1',
                text: t('toolbaritem.placeholderoption')
            }, {
                value: '2',
                text: t('toolbaritem.placeholderoption')
            }];
        }

        if (type === 'checkboxes') {
            values.validation.type = 'array';
        }

        if (type === 'number') {
            values.validation.type = 'number';
        }

        if (type === 'datepicker' || type === 'timepicker') {
            values.dateTimePickerSettings = {
                limitTimeRange: false,
                includeTimeSelect: false,
                minTime: startOfToday(),
                maxTime: endOfToday()
            }
        }

        if (type === 'range') {
            values.rangeSettings = {
                min: 0,
                max: 100,
                step: 1
            };
        }

        if (type === 'article') {
            values.articles = [];
        }

        if (type === 'address') {
            values.address = {
                postalCode: '',
                houseNumber: '',
                street: ''
            };
        }

        if (type === 'imageupload') {
            values.validation.type = 'array'
        }

        return values;
    }

    function addFormBuilderSpec(type: FormFieldType) {
        const field = getEmptyField(type);

        dispatch(createAddFormBuilderSpecAction(field));
    }

    function updateFormBuilderSpec(field: FormField) {
        dispatch(createUpdateFormBuilderSpecAction(field));
    }

    function moveFormBuilderSpecField(sortable: ISortable) {
        dispatch(createMoveFormBuilderSpecFieldAction(sortable))
    }

    function deleteFormBuilderSpecField(name: string) {
        dispatch(createDeleteFormBuilderSpecFieldAction(name))
    }

    function updateFormMetaData(metaData: FormMetaData) {
        dispatch(createUpdateFormBuilderSpecMetaDataAction(metaData));
    }

    function resetFormBuilder() {
        dispatch(createResetFormBuilderAction());
    }

    async function createNewForm(form: FormCreate, tenantId?: string) {
        try {
            const response = await formApi.createNewForm(form);

            if (!response.data.id || !tenantId) {
                throw Error('No form id or tenant id available');
            }

            dispatch(createAddFormSummaryAction({
                id: response.data.id,
                name: form.name,
                version: form.version,
                description: form.description,
                tenantId
            }))

            showNotification(
                t('statusmessage:createdsuccessfully').replace('__replace__', t('form'))
            );

            history.push(createFormsPath());
        } catch (error) {
            showErrorNotification(`Something went wrong creating the form: ${error}`);
        }
    }

    async function putForm(form: FormDetails) {
        if (!formDetail) {
            return;
        }

        try {
            await formApi.updateForm(formDetail.id, form);

            dispatch(createUpdateFormSummaryAction({
                formId: formDetail.id
            }));

            showNotification(
                t('statusmessage:updatedsuccessfully').replace('__replace__', t('form'))
            );

            history.push(createFormsPath());
        } catch (error) {
            showErrorNotification(`Something went wrong updating the form: ${error}`);
        }
    }

    return {
        form: formDetail,
        formSummary: form.summary,
        getFormById,
        getForms,
        deleteFormById,
        addFormBuilderSpec,
        formBuilder: form.builder,
        updateFormBuilderSpec,
        moveFormBuilderSpecField,
        deleteFormBuilderSpecField,
        updateFormMetaData,
        createNewForm,
        resetFormBuilder,
        putForm
    };
}
