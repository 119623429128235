// tslint:disable
/**
 * Authentication API
 * Authentication Service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@vansloophout.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
// Some imports not used depending on template conditions
import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

export const BASE_PATH = 'http://localhost'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

globalAxios.interceptors.request.use((config) => {
  document.body.classList.add('global-loading');

  return config;
});

globalAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    document.body.classList.remove('global-loading');

    return response;
  },
  async (error) => {
    document.body.classList.remove('global-loading');

    if (!error.response || error.response.status !== 401) {
      return;
    }

    // // @todo fixme
    // browserHistory.push(createLoginPath());

    // try {
    //   reduxStore.dispatch(createLogoutAction());
    // } catch (errorx) {
    //   showErrorNotification('Your session has timed out');
    // }

    return Promise.reject(error);
  },
);
