import { createReducer } from 'redux-act';
import { setThemeAction, SetThemeActionParams } from '../action/settingsActionFactory';
import { getTenantConfiguration } from '../../utils/tenantConfiguration';

export const DEFAULT_THEME = 'default';
export const VANSLOOPHOUT_THEME = 'vansloophout';
export const INTOWOOD_THEME = 'intowood';
export const VANHALTEREN_THEME = 'vanhalteren';
export const WEIJ_THEME = 'weijbv';
export const HK_THEME = 'handigekasten';
export const HAVEWOOD_THEME = 'havewood';
export const SANDBOX_THEME = 'sandbox';
export const ELDEROAK_THEME = 'elderoak';

export interface SettingsReducerState {
    theme: string;
    appTitle?:string;
    avDocuments?: { avCategory:'B2C'|'B2B', documentName: string, fileName: string }[];
}

const initial: SettingsReducerState = {
    theme: getTenantConfiguration() ? getTenantConfiguration().theme : DEFAULT_THEME,
    appTitle: getTenantConfiguration() ? getTenantConfiguration().appTitle || 'Scope' : 'Scope',
    avDocuments: getTenantConfiguration() ? getTenantConfiguration().avDocuments : []
};

export const settingsReducer = createReducer<SettingsReducerState>({}, initial);

settingsReducer.on(setThemeAction, (state: SettingsReducerState, payload: SetThemeActionParams) => ({
    ...state,
    theme: payload.theme
}));

export default settingsReducer;
