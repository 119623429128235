import React, { FC } from 'react';

type Props = {
    title?: string;
    visible?: boolean;
}

const Fieldset: FC<Props> = ({title, visible, children}) => {
    if (visible === false)
      return null;

  
    return (
        <fieldset className="form-fieldset">
            {title && (
                <div className="form-fieldset-header">
                    <h2 className="form-fieldset-title">{title}</h2>
                </div>
            )}
            {children}
        </fieldset>
    );
};

export default Fieldset;
