import { applyMiddleware, StoreEnhancer, Middleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';

const composeEnhancer = composeWithDevTools({
    trace: true,
});

export const sagaMiddleWare = createSagaMiddleware();

export function createMiddlewareChain(): StoreEnhancer {
    const middlewares: Middleware[] = [
        sagaMiddleWare
    ];

    return composeEnhancer(applyMiddleware(...middlewares));
}
