import { createReducer } from 'redux-act';
import produce from 'immer';
import { Category, Article } from '../../api/stock';
import { createGetArticlesAction, createGetCategoriesAction, createSetArticleAction, createSetCategoryAction, MutateArticleParams, createMutateArticleAction, createDeleteCategoryAction, AddArticleAmountParams, createAddArticleAmountAction } from '../action/stockActionFactory';

export interface StockReducerState {
    categories: {
        [id: number]: Category;
    };
    articles: {
        [articleNumber: string]: Article;
    }
}

const initialState: StockReducerState = {
    articles: {},
    categories: {}
};

export const stockReducer = createReducer<StockReducerState>({}, initialState);

stockReducer.on(createGetArticlesAction, (state: StockReducerState, payload: Article[]) => produce(state, (draft) => {
    payload.forEach((article) => {
        if (!article.id) {
            return;
        }

        draft.articles[article.id] = article;
    });
}));

stockReducer.on(createGetCategoriesAction, (state: StockReducerState, payload: Category[]) => produce(state, (draft) => {    
    payload.forEach((category) => {
        if (!category.id) {
            return;
        }

        draft.categories[category.id] = category;
    });
}));

stockReducer.on(createSetArticleAction, (state: StockReducerState, payload: Article) => produce(state, (draft) => {
    if (!payload.id) {
        return;
    }
    
    draft.articles[payload.id] = payload;
}));

stockReducer.on(createSetCategoryAction, (state: StockReducerState, payload: Category) => produce(state, (draft) => {
    if (!payload.id) {
        return;
    }
    
    draft.categories[payload.id] = payload;
}));

stockReducer.on(createMutateArticleAction, (state: StockReducerState, payload: MutateArticleParams[]) => produce(state, (draft) => {    
    payload.forEach((mutation) => {
        const article = draft.articles[mutation.id];

        if (!article || !article.amount) {
            return;
        }

        article.amount += mutation.amount;
    });
}));

stockReducer.on(createDeleteCategoryAction, (state: StockReducerState, payload: string) => produce(state, (draft) => {
    if (!draft.categories[parseInt(payload)]) {
        return;
    }

    delete draft.categories[parseInt(payload)];
}));

stockReducer.on(createAddArticleAmountAction, (state: StockReducerState, payload: AddArticleAmountParams) => produce(state, (draft) => {
    const article = draft.articles[payload.id];

    if (typeof article.amount === 'undefined') {
        return;
    }

    article.amount += payload.amount;
}));

export default stockReducer;
