import { Toast } from "primereact/toast";
import { createContext, createRef, Ref } from "react";

export type ToastSeverityType = 'success' | 'info' | 'warn' | 'error' | undefined

export interface INotificationContext {
    toastRef?: Ref<Toast>
    showNotification: (message: string, type?: ToastSeverityType, options?: { detail?: string }) => void,
    showErrorNotification: (message: string) => void,
    showWarningNotification: (message: string) => void
}

const toastRef: Ref<Toast> = createRef<Toast>()

const showNotification = (message: string, type: ToastSeverityType = 'success', options: { detail?: string } = {}) => {
    toastRef.current?.show({ severity: type, summary: message, detail: options?.detail })
}

const showErrorNotification = (message: string) => showNotification("Error", 'error', { detail: message })
const showWarningNotification = (message: string) => showNotification("Warning", 'warn', { detail: message })
const defaultContext = { toastRef, showNotification, showErrorNotification, showWarningNotification }

export const NotificationContext = createContext<INotificationContext>({ showErrorNotification: () => {}, showNotification: () => { }, showWarningNotification: () => { } })

export const NotificationContextProvider = ({ children }) => <NotificationContext.Provider value={defaultContext}>
    <Toast ref={toastRef} />
    {children}
</NotificationContext.Provider>