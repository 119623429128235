/* tslint:disable */
/* eslint-disable */
/**
 * Message API
 * API for Scope Message notifications
 *
 * The version of the OpenAPI document: 2.0
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../shared/common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ConsultationMessage
 */
export interface ConsultationMessage {
    /**
     * 
     * @type {string}
     * @memberof ConsultationMessage
     */
    'contents': string;
}
/**
 * 
 * @export
 * @interface ConsultationRecord
 */
export interface ConsultationRecord {
    /**
     * 
     * @type {string}
     * @memberof ConsultationRecord
     */
    'domain': string;
    /**
     * 
     * @type {number}
     * @memberof ConsultationRecord
     */
    'timestamp': number;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRecord
     */
    'contents': string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationRecord
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * Health report
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'recipientRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'recipientUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'createdAt'?: string;
}

/**
 * MessagingApi - axios parameter creator
 * @export
 */
export const MessagingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Message} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (message?: Message, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(message, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging/messageCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (page?: number, pageSize?: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opens a SSE stream to retrieve new records for the given order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderConsultationEventStream: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrderConsultationEventStream', 'id', id)
            const localVarPath = `/messaging/consultation/o/{id}/stream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post a new consultation message
         * @param {number} id 
         * @param {ConsultationMessage} [consultationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrderConsultationMessage: async (id: number, consultationMessage?: ConsultationMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postOrderConsultationMessage', 'id', id)
            const localVarPath = `/messaging/consultation/o/{id}/stream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(consultationMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagingApi - functional programming interface
 * @export
 */
export const MessagingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Message} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(message?: Message, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(page?: number, pageSize?: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Opens a SSE stream to retrieve new records for the given order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderConsultationEventStream(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderConsultationEventStream(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post a new consultation message
         * @param {number} id 
         * @param {ConsultationMessage} [consultationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrderConsultationMessage(id: number, consultationMessage?: ConsultationMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrderConsultationMessage(id, consultationMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagingApi - factory interface
 * @export
 */
export const MessagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagingApiFp(configuration)
    return {
        /**
         * 
         * @param {Message} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(message?: Message, options?: any): AxiosPromise<void> {
            return localVarFp.createMessage(message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageCount(options?: any): AxiosPromise<number> {
            return localVarFp.getMessageCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<Array<Message>> {
            return localVarFp.getMessages(page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Opens a SSE stream to retrieve new records for the given order
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderConsultationEventStream(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getOrderConsultationEventStream(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post a new consultation message
         * @param {number} id 
         * @param {ConsultationMessage} [consultationMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrderConsultationMessage(id: number, consultationMessage?: ConsultationMessage, options?: any): AxiosPromise<void> {
            return localVarFp.postOrderConsultationMessage(id, consultationMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagingApi - object-oriented interface
 * @export
 * @class MessagingApi
 * @extends {BaseAPI}
 */
export class MessagingApi extends BaseAPI {
    /**
     * 
     * @param {Message} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public createMessage(message?: Message, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).createMessage(message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getMessageCount(options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getMessageCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getMessages(page?: number, pageSize?: number, order?: string, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getMessages(page, pageSize, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Opens a SSE stream to retrieve new records for the given order
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public getOrderConsultationEventStream(id: number, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).getOrderConsultationEventStream(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post a new consultation message
     * @param {number} id 
     * @param {ConsultationMessage} [consultationMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApi
     */
    public postOrderConsultationMessage(id: number, consultationMessage?: ConsultationMessage, options?: AxiosRequestConfig) {
        return MessagingApiFp(this.configuration).postOrderConsultationMessage(id, consultationMessage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/messaging/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * Get service health
         * @summary GET endpoint for health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * Get service health
     * @summary GET endpoint for health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


