import { FC } from 'react';
import logo from '../../../../assets/img/scope-new.png';
import vansloophoutLogo from '../../../../assets/img/logovansloophout.png';
import intowoodLogo from '../../../../assets/img/logointowood.png';
import vanhalterenLogo from '../../../../assets/img/logovanhalteren.png';
import weijLogo from '../../../../assets/img/weijlogo.png';
import hkLogo from '../../../../assets/img/handigekasten.png';
import havewoodLogo from '../../../../assets/img/havewood.png';
import sandboxLogo from '../../../../assets/img/logo-sandbox.png';
import elderOakLogo from '../../../../assets/img/logo-elderoak.png';

import {
  VANSLOOPHOUT_THEME,
  INTOWOOD_THEME,
  VANHALTEREN_THEME,
  WEIJ_THEME,
  HK_THEME,
  HAVEWOOD_THEME,
  SANDBOX_THEME,
  ELDEROAK_THEME,
} from '../../../../redux/reducer/settingsReducerFactory';
import { useHistory } from 'react-router';
import { createHomePath } from '../../../../routing/appUrlGenerator';
import { useTheme } from '../../../../hooks/useTheme';

type Props = {
  onClick?: () => void;
};

const Logo: FC<Props> = ({ onClick }) => {
  const history = useHistory();
  const { theme } = useTheme();

  function getLogo() {
    switch (theme) {
      case VANSLOOPHOUT_THEME:
        return vansloophoutLogo;
      case INTOWOOD_THEME:
        return intowoodLogo;
      case VANHALTEREN_THEME:
        return vanhalterenLogo;
      case WEIJ_THEME:
        return weijLogo;
      case HK_THEME:
        return hkLogo;
      case HAVEWOOD_THEME:
        return havewoodLogo;
      case SANDBOX_THEME:
        return sandboxLogo;
      case ELDEROAK_THEME:
        return elderOakLogo;

      default:
        return logo;
    }
  }

  return (
    <div className="layout-logo">
      <a
        href={createHomePath()}
        onClick={(e) => {
          e.preventDefault();

          if (onClick) {
            onClick();
          }

          history.push(createHomePath());
        }}
      >
        <img className="logo" alt="Logo" src={getLogo()} />
      </a>
    </div>
  );
};

export default Logo;
