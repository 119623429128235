import React, { useEffect, useRef, EffectCallback, useCallback } from 'react';

const useIsMounted = function useIsMounted() {
    const isMounted = React.useRef(false);

    React.useEffect(function setIsMounted() {
        isMounted.current = true;

        return function cleanupSetIsMounted() {
            isMounted.current = false;
        };
    }, []);

    return isMounted;
};

export const useUpdateEffect = function useUpdateEffect(effect: any, dependencies: any[] = []) {
    const isMounted = useIsMounted();
    const isInitialMount = useRef(true);

    useEffect(() => {
        let effectCleanupFunc = function noop() { };

        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effectCleanupFunc = effect() || effectCleanupFunc;
        }
        return () => {
            effectCleanupFunc();
            if (!isMounted.current) { 
                isInitialMount.current = true;
            }
        };
    }, dependencies); 
};

export const useOnMount = (effect: EffectCallback, dependencies: any[] = []) => {
    useEffect(effect, dependencies); 
}

export const useDebouncedEffect = (effect: any, delay = 0, deps: any[] = []) => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
}
