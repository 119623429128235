import { FC } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useModal } from '../../../hooks/useModal';
import InternalModal from '../../shared/controls/Modal';
import { useOnMount } from '../../../hooks/useUpdate';
import Button from '../../shared/controls/Button/Button';
import { createLoginPath } from '../../../routing/appUrlGenerator';
import { useHistory } from 'react-router';
import getUnixTime from 'date-fns/getUnixTime';
import { useTranslation } from 'react-i18next';

const LogoutModal: FC = () => {
    const { modalIsOpen, setModalIsOpen } = useModal();
    const { exp, handleLogOut } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();

    useOnMount(() => {
        openModalOnExpiration();
        const interval = setInterval(openModalOnExpiration, 60000); // 1 minute

        return () => {
            clearInterval(interval);
        };
    });

    function openModalOnExpiration() {
        if (!exp || exp > getUnixTime(new Date())) {
            return;
        }

        setModalIsOpen(true);
    }

    return (
        <InternalModal
            title={"Je bent uitgelogd"}
            isOpen={modalIsOpen}
            isSmall
        >
            <p>Je bent automatisch uitgelogd. Klik op inloggen om opnieuw in te loggen.</p>

            <Button
                onClick={() => {
                    handleLogOut();
                    history.push(createLoginPath());
                }}
            >
                {t('common:login')}
            </Button>
        </InternalModal>
    );
}

export default LogoutModal;