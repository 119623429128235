 import {faLanguage} from '@fortawesome/free-solid-svg-icons/faLanguage'; 
 import {faBell} from '@fortawesome/free-solid-svg-icons/faBell'; 
 import {faHeading} from '@fortawesome/free-solid-svg-icons/faHeading'; 
 import {faFont} from '@fortawesome/free-solid-svg-icons/faFont'; 
 import {faParagraph} from '@fortawesome/free-solid-svg-icons/faParagraph'; 
 import {faArrowsAltH} from '@fortawesome/free-solid-svg-icons/faArrowsAltH'; 
 import {faCaretSquareDown} from '@fortawesome/free-solid-svg-icons/faCaretSquareDown'; 
 import {faTags} from '@fortawesome/free-solid-svg-icons/faTags'; 
 import {faCheckSquare} from '@fortawesome/free-solid-svg-icons/faCheckSquare'; 
 import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle'; 
 import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus'; 
 import {faTextHeight} from '@fortawesome/free-solid-svg-icons/faTextHeight'; 
 import {faImage} from '@fortawesome/free-solid-svg-icons/faImage'; 
 import {faStar} from '@fortawesome/free-solid-svg-icons/faStar'; 
 import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons/faCalendarAlt'; 
 import {faPenFancy} from '@fortawesome/free-solid-svg-icons/faPenFancy'; 
 import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'; 
 import {faSlidersH} from '@fortawesome/free-solid-svg-icons/faSlidersH'; 
 import {faCameraRetro} from '@fortawesome/free-solid-svg-icons/faCameraRetro'; 
 import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash'; 
 import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare'; 
 import {faMinusSquare} from '@fortawesome/free-solid-svg-icons/faMinusSquare'; 
 import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes'; 
 import {faSave} from '@fortawesome/free-solid-svg-icons/faSave'; 
 import {faEye} from '@fortawesome/free-solid-svg-icons/faEye'; 
 import {faFileUpload} from '@fortawesome/free-solid-svg-icons/faFileUpload'; 
 import {faKey} from '@fortawesome/free-solid-svg-icons/faKey'; 
 import {faDoorClosed} from '@fortawesome/free-solid-svg-icons/faDoorClosed'; 
 import {faBoxes} from '@fortawesome/free-solid-svg-icons/faBoxes'; 
 import {faLockOpen} from '@fortawesome/free-solid-svg-icons/faLockOpen'; 
 import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope'; 
 import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons/faPhoneAlt'; 
 import {faFileAlt} from '@fortawesome/free-solid-svg-icons/faFileAlt'; 
 import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch'; 
 import {faClock} from '@fortawesome/free-solid-svg-icons/faClock'; 

// export only the icons which are used!
const icons = [
    faSearch,
    faArrowsAltH,
    faCalendarAlt,
    faCameraRetro,
    faCaretSquareDown,
    faCheckSquare,
    faDotCircle,
    faExternalLinkAlt,
    faEye,
    faFileUpload,
    faFileAlt,
    faFont,
    faHeading,
    faImage,
    faLanguage,
    faBell,
    faMinusSquare,
    faParagraph,
    faPenFancy,
    faPlus,
    faPlusSquare,
    faSave,
    faSlidersH,
    faStar,
    faTags,
    faTextHeight,
    faTimes,
    faTrash,
    faDoorClosed,
    faKey,
    faBoxes,
    faLockOpen,
    faPhoneAlt,
    faEnvelope,
    faFileAlt,
    faClock
];

export default icons;
