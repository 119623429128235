import formsReducer from "./formReducerFactory";
import authReducer from "./authReducerFactory";
import productReducer from "./productReducerFactory";
import settingsReducer from "./settingsReducerFactory";
import stockReducer from './stockReducerFactory';
import orderReducer from './orderReducerFactory';
import customerReducer from './customerReducerFactory';
import userReducer from './userReducerFactory';
import appStateReducer from "./appStateReducerFactory";

export function createRootReducer() {
    return {
        appState: appStateReducer,
        form: formsReducer,
        auth: authReducer,
        product: productReducer,
        settings: settingsReducer,
        stock: stockReducer,
        order: orderReducer,
        customer: customerReducer,
        user: userReducer,
    };
}
