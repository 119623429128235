import Fieldset from '../../formbuilder/elements/Fieldset';
import LoadingSplash from '../../LoadingSplash';
import { Button } from 'primereact/button';
import { FC, useLayoutEffect, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { TaskBody } from '../../../../api/task';
import { useTask } from '../../../../hooks/useTask';

export const TaskPickupForm: FC<{ onTaskPickedUp: () => void }> = ({
  onTaskPickedUp,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [availableTasks, setAvailableTasks] = useState<TaskBody[]>();

  const { getTasks, startTask } = useTask();
  useLayoutEffect(() => {
    // load task types
    getTasks().then((data) => {
      setAvailableTasks(data);
      setLoading(false);
    });
  }, []);

  const pickupButtonClicked = (task: TaskBody) => {
    if (task.id !== undefined)
      startTask(task).then(() => {
        onTaskPickedUp();
      });
  };

  return (
    <>
      <div className="p-fluid w-12" style={{ padding: '1ex 1em' }}>
        <Fieldset title="Taak oppakken">
          {loading && <LoadingSplash />}
          <div className="grid max-h-18rem overflow-y-scroll overflow-x-hidden">
            {availableTasks?.map((task) => (
              <div key={task.id} className="col-12">
                <Button onClick={() => pickupButtonClicked(task)}>
                  {task.taskName}
                </Button>
              </div>
            ))}
          </div>
        </Fieldset>
      </div>
    </>
  );
};
