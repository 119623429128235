import { createReducer } from 'redux-act';
import { addFormElement, clearForm, deleteFormElement, editFormElement, updateFormMeta, updateFormElementOrder, loadSavedForm, createGetFormsAction, createAddFormBuilderSpecAction, createUpdateFormBuilderSpecAction, createMoveFormBuilderSpecFieldAction, createDeleteFormBuilderSpecFieldAction, createUpdateFormBuilderSpecMetaDataAction, createSetFormBuilderAction, createResetFormBuilderAction, createUpdateFormSummaryAction, FormId, createDeleteFormAction, createAddFormSummaryAction } from '../action/formActionFactory';
import { definedValue, notEmptyValue } from '../../utils/utilities';
import produce from 'immer';
import { FormDetails, FormSummary } from '../../api/forms';
import { getTime } from 'date-fns';
import { FormField, FormMetaData, ISortable } from '../../components/shared/formbuilder/utils/interfaces';
import arrayMove from 'array-move';
import { cloneDeep } from 'lodash';

export interface IFormBuilder {
    id: string;
    name: string;
    description: string;
    version: number;
    sendsnotification: string[];
    notificationTemplateFinished: string;
    notificationTemplateStart: string;
    formSpec: FormField[];
}

export interface FormReducerState {
    builder: IFormBuilder
    summary: {
        [id: string]: FormSummary;
    }
    updatedAt?: number;
}

const initialBuilderState: any = {
    name: 'unnamed form',
    description: '',
    version: 0,
    sendsnotification:[],
    formSpec: [],
};

const initialState = {
    builder: initialBuilderState,
    summary: {}
};

export const formReducer = createReducer<FormReducerState>({}, initialState);

formReducer.on(createGetFormsAction, (state: FormReducerState, payload: FormSummary[]) => produce(state, (draft) => {
    payload.forEach((form) => {
        draft.summary[form.id] = form;
    });

    draft.updatedAt = getTime(new Date());
}));

formReducer.on(addFormElement, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    let element = payload.elementData;

    if (!definedValue(element)) {
        return;
    }

    element = {
        ...element,
        id: draft.builder.formSpec.reduce((maxId: number, el: any) => Math.max(el.id, maxId), -1) + 1
    };

    draft.builder.formSpec = [
        ...draft.builder.formSpec,
        element
    ];
}));

formReducer.on(clearForm, (state: FormReducerState) => produce(state, (draft) => {
    draft.builder = initialBuilderState;
}));

formReducer.on(deleteFormElement, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    if (!payload.elementId) {
        return;
    }

    draft.builder.formSpec = draft.builder.formSpec.filter((x: any) => x.id !== payload.elementId);
}));

formReducer.on(editFormElement, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    let element = payload.elementData;

    if (!definedValue(payload.elementData) || notEmptyValue(element.id)) {
        return;
    }

    element = {
        ...element,
        id: draft.builder.formSpec.reduce((maxId, el: any) => Math.max(el.id, maxId), -1) + 1
    };

    draft.builder.formSpec = [
        ...draft.builder.formSpec,
        element
    ];
}));

formReducer.on(updateFormMeta, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    if (!definedValue(payload.metaData)) {
        return;
    }

    draft.builder.name = payload.metaData.name || draft.builder.name;
    draft.builder.version = payload.metaData.version || draft.builder.version;
    draft.builder.description = payload.metaData.description || draft.builder.description;
}));

formReducer.on(updateFormElementOrder, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    draft.builder.formSpec = payload.elements;
}));

formReducer.on(loadSavedForm, (state: FormReducerState, payload: any) => produce(state, (draft) => {
    draft.builder = payload.formData;
}));

formReducer.on(createAddFormBuilderSpecAction, (state: FormReducerState, payload: FormField) => produce(state, (draft) => {
    draft.builder.formSpec.push(payload);
}));

formReducer.on(createUpdateFormBuilderSpecAction, (state: FormReducerState, payload: FormField) => produce(state, (draft) => {
    const currentIndex = draft.builder.formSpec.findIndex((field) => field.name === payload.name);

    if (currentIndex === -1) {
        return;
    }

    draft.builder.formSpec.splice(currentIndex, 1, payload);
}));

formReducer.on(createMoveFormBuilderSpecFieldAction, (state: FormReducerState, payload: ISortable) => produce(state, (draft) => {
    const newSpec = arrayMove(draft.builder.formSpec, payload.dragIndex, payload.hoverIndex);

    draft.builder.formSpec = newSpec;
}));

formReducer.on(createDeleteFormBuilderSpecFieldAction, (state: FormReducerState, name: string) => produce(state, (draft) => {
    const index = draft.builder.formSpec.findIndex((spec) => spec.name === name);

    if (index === -1) {
        return;
    }

    draft.builder.formSpec.splice(index, 1);
}));

formReducer.on(createUpdateFormBuilderSpecMetaDataAction, (state: FormReducerState, payload: FormMetaData) => produce(state, (draft) => {
    draft.builder.name = payload.name;
    draft.builder.description = payload.description;
    draft.builder.version = payload.version;
    draft.builder.sendsnotification = payload.sendsnotification;
    draft.builder.notificationTemplateStart = payload.notificationTemplateStart;
    draft.builder.notificationTemplateFinished = payload.notificationTemplateFinished;
}));

formReducer.on(createSetFormBuilderAction, (state: FormReducerState, payload: FormDetails) => produce(state, (draft) => {
    draft.builder.name = payload.name;

    if (payload.description) {
        draft.builder.description = payload.description;
    }
    
    draft.builder.version = payload.version;

    if (payload.formSpec) {
        // @ts-ignore
        draft.builder.formSpec = payload.formSpec;
    }
}));

formReducer.on(createResetFormBuilderAction, (state: FormReducerState) => produce(state, (draft) => {
    draft.builder = cloneDeep(initialBuilderState);
}));

formReducer.on(createUpdateFormSummaryAction, (state: FormReducerState, payload: { formId: string }) => produce(state, (draft) => {
    draft.summary[payload.formId].name = draft.builder.name;
    draft.summary[payload.formId].description = draft.builder.description;
    draft.summary[payload.formId].version = draft.builder.version;
}));

formReducer.on(createDeleteFormAction, (state: FormReducerState, payload: FormId) => produce(state, (draft) => {
    delete draft.summary[payload.formId];
}));

formReducer.on(createAddFormSummaryAction, (state: FormReducerState, payload: FormSummary) => produce(state, (draft) => {
    draft.summary[payload.id] = payload;
}));

export default formReducer;
