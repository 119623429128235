import getWeek from "date-fns/getWeek";


export function createHomePath() {
    return '/';
}

export function createHomeNewPath() {
    return '/homenew';
}


export function createLoginPath() {
    return '/login';
}

export function createTrengoPath() {
    return '/trengo';
}

export function createNotificationsPath() {
    return '/notifications';
}

export function createOrdersPath() {
    return '/orders';
}

export function createCustomersPath() {
    return '/customers';
}

export function createCustomerCreatePath() {
    return `${createCustomersPath()}/new`
}

export function createCustomersDetailPath(id?: string) {
    if (!id) {
        return `${createCustomersPath()}/:id`;
    }

    return `${createCustomersPath()}/${id}`;
}

export function createOrdersNewPath() {
    return `${createOrdersPath()}/new`;
}

export function createOrdersDetailPath(id?: string) {
    if (!id) {
        return `${createOrdersPath()}/:id`;
    }

    return `${createOrdersPath()}/${id}`;
}
// export function createOrdersDetailNewPath(id?: string) {
//     if (!id) {
//         return `${createOrdersPath()}/:id/v2`;
//     }

//     return `${createOrdersPath()}/${id}/v2`;
// }

export function createCopyOrderPath(id?:string) {
    return `${createOrdersDetailPath(id)}/create-copy`
}

export function createOrdersAdjustWorkPath(id?: string, year?: number, week?: number) {
    let today = new Date();
    if (!year) { year = today.getUTCFullYear(); }
    if (!week) { week = getWeek(today); }

    if (!id) {
        return `/work/adjust/:id/:year/:week`;
    }
    return `/work/adjust/${id}/${year}/${week}`;
}


export function createOrdersDeliveryPath() {
    return `${createPlanningOverviewPath()}/delivery`;
}

export function createOrdersDeliveryCreateRoutePath() {
    return `${createPlanningOverviewPath()}/delivery/plan-route`;
}

export function createOrdersDeliveryDetailPath(id?: number) {
    if (!id) {
        return `${createOrdersDeliveryPath()}/:id`;
    }

    return `${createOrdersDeliveryPath()}/${id}`;
}

export function createOrdersInvoicePath(id?: string, customerId?: string) {
    if (!customerId) {
        return `${createOrdersDetailPath(id)}/invoice/:customerId`;
    }
    return `${createOrdersDetailPath(id)}/invoice/${customerId}`;
}

export function createOrderSchedulePath() {
    return `${createOrdersPath()}/schedule`
}

export function createWorkInProgressPath() {
    return `/work-in-progress`
}

export function createTenantsPath() {
    return '/tenants';
}

export function createTenantsNewPath() {
    return `${createTenantsPath()}/new`;
}

export function createTenantsDetailPath(id?: string) {
    if (!id) {
        return `${createTenantsPath()}/:id`;
    }

    return `${createTenantsPath()}/${id}`;
}

export function createFormsPath() {
    return '/forms';
}

export function createFormsNewPath() {
    return `${createFormsPath()}/new`;
}

export function createFormsDetailPath(id?: string) {
    if (!id) {
        return `${createFormsPath()}/:id`;
    }

    return `${createFormsPath()}/${id}`;
}

export function createUsersPath() {
    return '/users';
}

export function createUserRateDetails(id?: number) {
    if (!id) {
        return `${createUsersPath()}/:id/rates`;
    }
    return `${createUsersPath()}/${id}/rates`;
}

export function createUsersFactoryRate() {
   return `${createUsersPath()}/user-rates`; 
}

export function createUsersNewPath() {
    return `${createUsersPath()}/new`;
}

export function createUsersDetailPath(id?: string) {
    if (!id) {
        return `${createUsersPath()}/:id/edit`;
    }

    return `${createUsersPath()}/${id}/edit`;
}

export function createUsersViewPath(id?: string) {
    if (!id) {
        return `${createUsersPath()}/:id`;
    }

    return `${createUsersPath()}/${id}`;
}

export function createShoppingPath() {
    return '/shopping';
}

export function createShoppingNewPath() {
    return `${createShoppingPath()}/new`;
}

export function createProductsPath() {
    return '/products';
}

export function createMarketingPath() {
    return '/marketing';
}

export function createProductsNewPath() {
    return `${createProductsPath()}/new`;
}

export function createProductsDetailPath(id?: number) {
    if (!id) {
        return `${createProductsPath()}/:id`;
    }

    return `${createProductsPath()}/${id}`;
}

export function createArticlesPath() {
    return '/articles';
}

export function createArticlesDetailPath(id?: number) {
    if (!id) {
        return `${createArticlesPath()}/:id`;
    }

    return `${createArticlesPath()}/${id}`;
}

export function createArticlesNewPath() {
    return `${createArticlesPath()}/new`;
}

export function createArticlesAmountMutationPath(id?: number) {
    if (!id) {
        return `${createArticlesPath()}/:id/amount-mutation`;
    }

    return `${createArticlesPath()}/${id}/amount-mutation`;
}

export function createCategoriesPath() {
    return '/categories';
}

export function createCategoriesDetailPath(id?: number) {
    if (!id) {
        return `${createCategoriesPath()}/:id`;
    }

    return `${createCategoriesPath()}/${id}`;
}

export function createCategoriesNewPath() {
    return `${createCategoriesPath()}/new`;
}

export function createStatisticsPath() {
    return '/statistics';
}

export function createSalesMarginsStatisticsPath() {
    return `${createStatisticsPath()}/salesmargins`;
}

export function createStatisticsWorkingHoursPath(year?: number, week?: number) {

    if (!year) {
        return `/statistics/working-hours/:year/:week`;
    }

    let today = new Date();

    if (!year) { year = today.getUTCFullYear(); }
    if (!week) { week = getWeek(today); }

    return `/statistics/working-hours/${year}/${week}`;
}

export function createPlanningOverviewPath() {
    return '/planning';
}

export function createAVPath() {
    return '/termsandconditions'
}

export function createConfigurationPath() {
    return '/configuration'
}

export function createConfigurationProcessEmailsPath() {
    return `${createConfigurationPath()}/process-emails`
}

export function createConfigurationTenantSettingsPath() {
    return `${createConfigurationPath()}/tenant-settings`
}

export function createSearchPath() {
    return '/search'
}

export function createTaskPath() {
    return `${createConfigurationPath()}/task`
}

export function createNewTaskPath() {
    return `${createTaskPath()}/new`
}
export function createUpdateTaskPath(id?: string) {
    if (!id) {
        return `${createTaskPath()}/:id/edit`;
    }

    return `${createTaskPath()}/${id}/edit`;
}

export function createLogisticsPath() {
    return '/logistics';
}


export function createExpeditionPath() {
    return `${createLogisticsPath()}/expedition`
}

