import axios, { CancelTokenSource } from 'axios';
import Lightbox from 'react-image-lightbox';
import { AiOutlineSearch } from '@react-icons/all-files/ai/AiOutlineSearch';
import { Configuration, QueryResultObject, SearchApi } from '../../../../api/search';
import {
  createCustomersDetailPath,
  createHomePath,
  createOrdersDetailPath,
  createSearchPath
  } from '../../../../routing/appUrlGenerator';
import { CustomerSearchResult } from './SearchResults/Customer';
import {
  FC,
  FormEvent,
  useRef,
  useState
  } from 'react';
import { ImageSearchResult } from './SearchResults/Image';
import { OrderSearchResult } from './SearchResults/Order';
import { useAuth } from '../../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { useOnMount } from '../../../../hooks/useUpdate';
import { useTranslation } from 'react-i18next';
import 'react-image-lightbox/style.css';
import Autosuggest, {
  ChangeEvent, SuggestionSelectedEventData,
} from 'react-autosuggest';

type Props = {
  onSearchInputFocusChange: (value: boolean) => void;
}

export const SearchBar: FC<Props> = ({ onSearchInputFocusChange }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<QueryResultObject[]>([]);
  const history = useHistory();
  const { auth } = useAuth();
  const [lightbox, setLightbox] = useState({
    isOpen: false,
    src: '',
  });
  const { t } = useTranslation()
  let axiosCancelToken = useRef<CancelTokenSource>();

  function isInSearch(){
    return window.location.pathname === createSearchPath();
  }

  useOnMount(() => {
    if(!isInSearch())
    {
      setValue('');
    }
  }, [window.location.pathname]);


  useOnMount(() => {
    setNewCancelToken();
  }, []);


  function setLightboxState(newValues: any) {
    setLightbox({
      ...lightbox,
      ...newValues,
    });
  }

  async function fetchSuggestions(newValue: string){
    if (newValue === '') {
      return;
    }

    const apiOptions = new Configuration({
      basePath: process.env.REACT_APP_SERVICE_URL_SEARCH,
      accessToken: auth?.jwt,
    });

    const searchApi = new SearchApi(apiOptions);
    const data = await searchApi.getHypersearchSearch(encodeURIComponent(newValue), ["orders", "customers"], 20, {
      cancelToken: axiosCancelToken.current?.token
    });

    if (!data?.data.results) {
      return;
    }

    setSuggestions(data.data.results);
  }

  function onChange(_event: FormEvent, { newValue }: ChangeEvent) {
    setValue(newValue);
    fetchSuggestions(newValue);
  }

  function setNewCancelToken(){
    axiosCancelToken.current = axios.CancelToken.source();
  }
  
  function cancelToken(){
    axiosCancelToken.current?.cancel();
    setNewCancelToken();
  }

  function onKeyDown(e: any){
    if (e.key === 'Enter') {
      cancelToken();
      e.currentTarget.blur();
      history.push(`${createSearchPath()}?q=${value}`)
    }
  }
  
  async function onFetch({value}) {
  }

  function onClear() {
    setSuggestions([]);
    setValue("");
  }

  function getLink(id: string, type: SuggestionType) {
    switch (type) {
      case 'customers':
        return createCustomersDetailPath(id);
      case 'orders':
        return createOrdersDetailPath(id);
      //   case 'product':
      //     return createProductsDetailPath(parseInt(id));
      case 'order-image':
        return createOrdersDetailPath(id);
      //   case 'image':
      //     return '#';
      default:
        return createHomePath();
    }
  }

  function renderSuggestion({ type, objectData }: QueryResultObject) {
    if (!objectData) {
      return null;
    }

    switch (type) {
      case 'image':
        let imagedata = objectData as IQueryImage;

        return (
          <ImageSearchResult
            objectData={imagedata}
            onClick={handleImageClick}
          />
        );
      case 'customers':
      case 'customer':
        let customerdata = objectData as ICustomerMeta;

        return <CustomerSearchResult objectData={customerdata} />;
      case 'orders':
      case 'order':
        //quick fix for SNB-416
        let orderdata = objectData as IOrderMeta;
        return <OrderSearchResult objectData={orderdata} />;

      default:
        return null;
    }
  }

  function handleImageClick(values: IQueryImageLightbox) {
    setLightboxState(values);
  }

  function onSelected(
    _event: FormEvent,
    { suggestion }: SuggestionSelectedEventData<QueryResultObject>,
  ) {
    if (suggestion.type === 'image' && suggestion.objectData) {
      const data = suggestion.objectData as IQueryImage;

      setLightboxState({
        isOpen: true,
        src: data.filename,
      });

      return;
    }

    if (!suggestion.id || !suggestion.type) {
      return;
    }

    const type = suggestion.type as SuggestionType;
    history.push(getLink(suggestion.id!, type));

    onClear();
  }

  function getSuggestionValue(suggestion: QueryResultObject) {
    return value;
  }

  function onBlur(){
    onSearchInputFocusChange(false);
    setSuggestions([]);
  }

  function onFocus(){
    onSearchInputFocusChange(true);
    fetchSuggestions(value);
  }

  return (
    <>
      <div className="layout-topbar-search-holder">
          <AiOutlineSearch />
          <form action="#">
          <Autosuggest
          suggestions={suggestions}
          alwaysRenderSuggestions
          onSuggestionsFetchRequested={onFetch}
          // onSuggestionsClearRequested={onClear}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={{
            value,
            onChange,
            onKeyDown,
            onFocus,
            onBlur,
            className: 'p-inputtext p-component form-control',
            placeholder: `${t('common:search')}...`,
            autoComplete: `off`,
            id: 'globalsearchbox'
          }}
          onSuggestionSelected={onSelected}
        />
        </form>
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={lightbox.src}
            onCloseRequest={() => {
              setLightboxState({
                isOpen: false,
              });
            }}
          />
        )}
      </div>
    </>
  );
};

export default SearchBar;
