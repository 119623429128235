/* tslint:disable */
/* eslint-disable */
/**
 * Task API
 * Facilitates the creation, modification, and querying of \"tasks\"
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreatedResponse
 */
export interface CreatedResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatedResponse
     */
    'taskId'?: string;
}
/**
 * 
 * @export
 * @interface Detailedworkstats
 */
export interface Detailedworkstats {
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'taskname'?: string;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'day': number;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof Detailedworkstats
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof Detailedworkstats
     */
    'totaltime': number;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface FullTimeLog
 */
export interface FullTimeLog {
    /**
     * 
     * @type {number}
     * @memberof FullTimeLog
     */
    'totalTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullTimeLog
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<FullTimeLogItem>}
     * @memberof FullTimeLog
     */
    'worklog'?: Array<FullTimeLogItem>;
}
/**
 * 
 * @export
 * @interface FullTimeLogItem
 */
export interface FullTimeLogItem {
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'started'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'stopped'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'by'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullTimeLogItem
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullTimeLogItem
     */
    'formId'?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface TaskBody
 */
export interface TaskBody {
    /**
     * 
     * @type {number}
     * @memberof TaskBody
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskBody
     */
    'taskName': string;
    /**
     * 
     * @type {string}
     * @memberof TaskBody
     */
    'taskDescription'?: string;
}
/**
 * 
 * @export
 * @interface TasksInProgressRecord
 */
export interface TasksInProgressRecord {
    /**
     * 
     * @type {number}
     * @memberof TasksInProgressRecord
     */
    'taskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TasksInProgressRecord
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TasksInProgressRecord
     */
    'name'?: string;
    /**
     * Unix timestamp
     * @type {number}
     * @memberof TasksInProgressRecord
     */
    'start'?: number;
}
/**
 * 
 * @export
 * @interface TasksWorkedOnFromRecord
 */
export interface TasksWorkedOnFromRecord {
    /**
     * 
     * @type {number}
     * @memberof TasksWorkedOnFromRecord
     */
    'taskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TasksWorkedOnFromRecord
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TasksWorkedOnFromRecord
     */
    'name'?: string;
    /**
     * Unix timestamp
     * @type {number}
     * @memberof TasksWorkedOnFromRecord
     */
    'start'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TasksWorkedOnFromRecord
     */
    'activeNow'?: boolean;
    /**
     * seconds spent in this session
     * @type {number}
     * @memberof TasksWorkedOnFromRecord
     */
    'timeSpentNow'?: number;
    /**
     * seconds spent in total
     * @type {number}
     * @memberof TasksWorkedOnFromRecord
     */
    'timeSpentTotal'?: number;
}
/**
 * 
 * @export
 * @interface TimeLogItem
 */
export interface TimeLogItem {
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'lastStarted'?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'lastStopped'?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeLogItem
     */
    'totalTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeLogItem
     */
    'by': string;
    /**
     * 
     * @type {boolean}
     * @memberof TimeLogItem
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface UpdatedResponse
 */
export interface UpdatedResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdatedResponse
     */
    'taskId'?: string;
}
/**
 * 
 * @export
 * @interface WorkStatusLogItem
 */
export interface WorkStatusLogItem {
    /**
     * 
     * @type {string}
     * @memberof WorkStatusLogItem
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof WorkStatusLogItem
     */
    'by': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkStatusLogItem
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface Workstats
 */
export interface Workstats {
    /**
     * 
     * @type {string}
     * @memberof Workstats
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'week': number;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'day': number;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'year': number;
    /**
     * 
     * @type {string}
     * @memberof Workstats
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof Workstats
     */
    'totaltime': number;
}

/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new task on the system
         * @summary Create new Task
         * @param {TaskBody} [taskBody] Full description of the task to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (taskBody?: TaskBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch detailed worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedTaskWorklogStatsForFactoryUsers: async (year: number, week: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getDetailedTaskWorklogStatsForFactoryUsers', 'year', year)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getDetailedTaskWorklogStatsForFactoryUsers', 'week', week)
            const localVarPath = `/tasks/stats/worklog/{year}/{week}/detailed`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get active tasks for active user
         * @summary Fetch active tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActiveTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskWorklogStatsForFactoryUsers: async (year: number, week: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getTaskWorklogStatsForFactoryUsers', 'year', year)
            // verify required parameter 'week' is not null or undefined
            assertParamExists('getTaskWorklogStatsForFactoryUsers', 'week', week)
            const localVarPath = `/tasks/stats/worklog/{year}/{week}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"week"}}`, encodeURIComponent(String(week)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of tasks from the system\\n\\nReturns only the tasks that are available for the logged-in tenant
         * @summary Fetch tasks list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all tasks currently in progress
         * @summary Fetch in-progress tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInProgress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/in-progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches realtime view of tasks worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkedOnToday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/stats/worked-on-today`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs work start time for active user on given task
         * @summary Pick up task
         * @param {string} id logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkOnTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startWorkOnTask', 'id', id)
            const localVarPath = `/tasks/task/{id}/pickup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs end time of work on specified task
         * @summary Release task
         * @param {string} id logs work stop time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkOnTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stopWorkOnTask', 'id', id)
            const localVarPath = `/tasks/task/{id}/release`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update task on the system
         * @summary update Task
         * @param {TaskBody} [taskBody] Full description of the task to update (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (taskBody?: TaskBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new task on the system
         * @summary Create new Task
         * @param {TaskBody} [taskBody] Full description of the task to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(taskBody?: TaskBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(taskBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * fetch detailed worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Detailedworkstats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetailedTaskWorklogStatsForFactoryUsers(year, week, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get active tasks for active user
         * @summary Fetch active tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyActiveTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskBody>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyActiveTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workstats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskWorklogStatsForFactoryUsers(year, week, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a list of tasks from the system\\n\\nReturns only the tasks that are available for the logged-in tenant
         * @summary Fetch tasks list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskBody>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all tasks currently in progress
         * @summary Fetch in-progress tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTasksInProgress(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TasksInProgressRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTasksInProgress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetches realtime view of tasks worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkedOnToday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TasksWorkedOnFromRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkedOnToday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs work start time for active user on given task
         * @summary Pick up task
         * @param {string} id logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startWorkOnTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startWorkOnTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs end time of work on specified task
         * @summary Release task
         * @param {string} id logs work stop time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopWorkOnTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopWorkOnTask(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update task on the system
         * @summary update Task
         * @param {TaskBody} [taskBody] Full description of the task to update (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(taskBody?: TaskBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(taskBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * Create a new task on the system
         * @summary Create new Task
         * @param {TaskBody} [taskBody] Full description of the task to create (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(taskBody?: TaskBody, options?: any): AxiosPromise<CreatedResponse> {
            return localVarFp.createTask(taskBody, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch detailed worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: any): AxiosPromise<Array<Detailedworkstats>> {
            return localVarFp.getDetailedTaskWorklogStatsForFactoryUsers(year, week, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Get active tasks for active user
         * @summary Fetch active tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActiveTasks(options?: any): AxiosPromise<Array<TaskBody>> {
            return localVarFp.getMyActiveTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * fetch worklog statistics for factory users in given year/week
         * @param {number} year 
         * @param {number} week 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: any): AxiosPromise<Array<Workstats>> {
            return localVarFp.getTaskWorklogStatsForFactoryUsers(year, week, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of tasks from the system\\n\\nReturns only the tasks that are available for the logged-in tenant
         * @summary Fetch tasks list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasks(options?: any): AxiosPromise<Array<TaskBody>> {
            return localVarFp.getTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all tasks currently in progress
         * @summary Fetch in-progress tasks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTasksInProgress(options?: any): AxiosPromise<Array<TasksInProgressRecord>> {
            return localVarFp.getTasksInProgress(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches realtime view of tasks worked on today
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkedOnToday(options?: any): AxiosPromise<Array<TasksWorkedOnFromRecord>> {
            return localVarFp.getWorkedOnToday(options).then((request) => request(axios, basePath));
        },
        /**
         * Logs work start time for active user on given task
         * @summary Pick up task
         * @param {string} id logs work start time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkOnTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.startWorkOnTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Logs end time of work on specified task
         * @summary Release task
         * @param {string} id logs work stop time for a form related to given order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkOnTask(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.stopWorkOnTask(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update task on the system
         * @summary update Task
         * @param {TaskBody} [taskBody] Full description of the task to update (including meta data)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(taskBody?: TaskBody, options?: any): AxiosPromise<UpdatedResponse> {
            return localVarFp.updateTask(taskBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * Create a new task on the system
     * @summary Create new Task
     * @param {TaskBody} [taskBody] Full description of the task to create (including meta data)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public createTask(taskBody?: TaskBody, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).createTask(taskBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch detailed worklog statistics for factory users in given year/week
     * @param {number} year 
     * @param {number} week 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getDetailedTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getDetailedTaskWorklogStatsForFactoryUsers(year, week, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get active tasks for active user
     * @summary Fetch active tasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getMyActiveTasks(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getMyActiveTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch worklog statistics for factory users in given year/week
     * @param {number} year 
     * @param {number} week 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getTaskWorklogStatsForFactoryUsers(year: number, week: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getTaskWorklogStatsForFactoryUsers(year, week, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of tasks from the system\\n\\nReturns only the tasks that are available for the logged-in tenant
     * @summary Fetch tasks list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getTasks(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all tasks currently in progress
     * @summary Fetch in-progress tasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getTasksInProgress(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getTasksInProgress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches realtime view of tasks worked on today
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public getWorkedOnToday(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).getWorkedOnToday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs work start time for active user on given task
     * @summary Pick up task
     * @param {string} id logs work start time for a form related to given order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public startWorkOnTask(id: string, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).startWorkOnTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs end time of work on specified task
     * @summary Release task
     * @param {string} id logs work stop time for a form related to given order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public stopWorkOnTask(id: string, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).stopWorkOnTask(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update task on the system
     * @summary update Task
     * @param {TaskBody} [taskBody] Full description of the task to update (including meta data)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public updateTask(taskBody?: TaskBody, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).updateTask(taskBody, options).then((request) => request(this.axios, this.basePath));
    }
}


