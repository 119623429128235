// @ts-nocheck

export const filterObjectKeys = (obj, predicate) =>
    Object.assign(
        ...Object.keys(obj)
            .filter((key) => predicate(key))
            .map((key) => ({ [key]: obj[key] })),
    );

export const definedValue = (v) => typeof v !== typeof undefined;
export const notEmptyValue = (v) => definedValue(v) && v !== '';

export function getStackTrace() {
    const error = new Error();
    const stack = error.stack
      .split('\n')
      .slice(2)
      .map((line) => line.replace(/\s+at\s+/, ''))
      .join('\n');
    return stack;
  }

export const intlPhone = (strPhone?:string, defaultCountryCode:string = "+31") =>{
  if (strPhone) {
    if (strPhone.startsWith("00")) {
      return strPhone.replace(/^00/,"+");
    }

    if (strPhone.startsWith("+"))
      return strPhone;

    if (strPhone.startsWith("0")) {
      return strPhone.replace(/^0/, defaultCountryCode)
    }

    return strPhone;
  }
}

/**
 * Retries a function n number of times before giving up
 */
export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  retryCount = 1
): Promise<Awaited<ReturnType<T>>> {
  const currRetry = typeof retryCount === 'number' ? retryCount : 1;
  try {
    const result = await fn(...args);
    return result;
  } catch (e) {
    console.log(`Retry ${currRetry} failed.`);
    if (currRetry > maxTry) {
      console.log(`All ${maxTry} retry attempts exhausted`);
      throw e;
    }
    return retry(fn, args, maxTry, currRetry + 1);
  }
}