import { useContext } from "react";
import { Configuration, TaskApi, TaskBody } from "../api/task";
import { ApplicationStateContext } from "../context/ApplicationContext";
import { useAuth } from "./useAuth";

export function useTask() {

    const { auth } = useAuth();
    const appContext = useContext(ApplicationStateContext)

    const taskApi = new TaskApi(new Configuration({
        basePath: process.env.REACT_APP_SERVICE_URL_TASK,
        accessToken: auth?.jwt
    }))


    const getActiveTasks : () => Promise<TaskBody[]> = async ()=> {
        try {
            let response = await taskApi.getMyActiveTasks()
            if (response.status === 200) {
                return response.data
            } 
        }
        catch {
            // TODO: LOG THIS
        }
        return []
    }

    const getTasks: () => Promise<TaskBody[]> = async () => {
        try {
            let response = await taskApi.getTasks()
            if (response.data !== undefined) {
                return response.data
            }
        } catch {
            // TODO: Log this!
        }
        return []
    }

    const startTask: (task: TaskBody) => Promise<boolean> = async (task) => {
        if (task.id !== undefined) {
            try {
                await taskApi.startWorkOnTask(task.id.toString())
                appContext?.setActiveTask(task.id)
                return true
            }
            catch {
                // TODO: Log this
            }
        }
        return false
    }

    const endTask: () => Promise<boolean> = async () => {
        if (appContext?.taskId !== undefined) {
            try {
                await taskApi.stopWorkOnTask(appContext.taskId.toString())
                appContext.setActiveTask(undefined)
                return true
            }
            catch {
                // TODO: Log this
            }
        }
        return false
    }

    const saveTask : (taskData: TaskBody, mode: 'create' | 'update') => Promise<boolean> = async(taskData, mode = 'create') => {
        
        if (mode === 'create') {
            try {
                let resp = await taskApi.createTask({ taskName: taskData.taskName, taskDescription: taskData.taskDescription, id:undefined })
                if (resp.status === 201) 
                    return true
            } catch (e) {
                // TODO: LOG THIS
            }
        } else {
            try {
                let resp = await taskApi.updateTask({ id: taskData.id, taskName: taskData.taskName, taskDescription: taskData.taskDescription })
                if (resp.status === 200) 
                    return true
            } catch (e) {
                // TODO: LOG THIS
            }
        }
        
        return false
    }

    return {
        getTasks,
        startTask,
        endTask,
        saveTask,
        getActiveTasks
    }

}