import { createAction } from 'redux-act';
import { Category, Article } from '../../api/stock';

export interface MutateArticleParams {
    id: number;
    amount: number;
}

export interface AddArticleAmountParams {
    id: number;
    amount: number;
}

export const createGetArticlesAction = createAction<Article[]>('Get articles');
export const createGetCategoriesAction = createAction<Category[]>('Get categories');

export const createSetArticleAction = createAction<Article>('Set article');
export const createSetCategoryAction = createAction<Category>('Set category');

export const createMutateArticleAction = createAction<MutateArticleParams[]>('Mutate article');
export const createAddArticleAmountAction = createAction<AddArticleAmountParams>('Add article amount');

export const createDeleteCategoryAction = createAction<string>('Delete category');

