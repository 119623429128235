import { useSelector } from 'react-redux';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { useOnMount } from './useUpdate';

export function useTheme() {
    const theme = useSelector((state: GlobalState) => state.settings.theme);
    
    useOnMount(() => {
        if (!theme) return;

        import(`../assets/styles/theme/${theme}/globals.scss`);

        const favicon = document.getElementById("favicon") as any;
        if (favicon) {
            const origurl = favicon.href as string;
            const newUrl = origurl.replace('favicon.ico', `favicon-${theme}.ico`);

            fetch(newUrl).then((data)=>{
                if (data.ok) {
                    favicon.href = newUrl;
                }
            })
        }
    });

    return {
        theme
    };
}
