import { createReducer } from 'redux-act';
import produce from 'immer';
import { getTime } from 'date-fns';
import { CustomerDetails, CustomerSummary } from '../../api/customer';
import { createFetchCustomersAction, createSetCustomerDetailsAction } from '../action/customerActionFactory';

export interface CustomerReducerState {
    summary: {
        [id: string]: CustomerSummary;
    },
    details: {
        [id: string]: CustomerDetails;
    }
    updatedAt?: number;
}

const initialState: CustomerReducerState = {
    summary: {},
    details: {}
};

const customerReducer = createReducer<CustomerReducerState>({}, initialState);

customerReducer.on(createFetchCustomersAction, (state: CustomerReducerState, payload: CustomerSummary[]) => produce(state, (draft) => {
    payload.forEach((customer) => {
        if (!customer.id) {
            return;
        }

        draft.summary[customer.id] = customer;
    });

    if (payload.length === 0) {
        draft.summary = initialState.summary;
    }

    draft.updatedAt = getTime(new Date());
}));

customerReducer.on(createSetCustomerDetailsAction, (state: CustomerReducerState, payload: CustomerDetails) => produce(state, (draft) => {
    if (!payload.id) {
        return;
    }

    draft.details[payload.id] = payload;
}));

export default customerReducer;