import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Configuration, UserApi, UserData } from '../api/user';
import { GlobalState } from '../interfaces/redux/GlobalState';
import {
  createFetchUsersAction,
  createRemoveUserAction,
} from '../redux/action/userActionFactory';
import { showErrorNotification, showNotification } from '../utils/notification';
import { CUSTOMER_USER } from './useAuth';
import { useMemo } from 'react';

export function useUser() {
  const dispatch = useDispatch();
  const auth = useSelector((state: GlobalState) => state.auth);
  const apiOptions = useMemo(
    () =>
      new Configuration({
        basePath: process.env.REACT_APP_SERVICE_URL_USER,
        accessToken: auth?.jwt,
      }),
    [auth?.jwt],
  );
  const user = useSelector((state: GlobalState) => state.user);
  const userApi = new UserApi(apiOptions);
  const { t } = useTranslation('usermanager');

  async function getUsers() {
    let response: AxiosResponse<UserData[]> | undefined;
    try {
      response = await userApi.getUsers();

      dispatch(createFetchUsersAction(response.data));
    } catch (error) {
      // BAD PRACTICE!
    }
  }

  async function removeUser(userName: string) {
    try {
      await userApi.deleteUserName(userName);

      dispatch(createRemoveUserAction(userName));

      showNotification(
        t('statusmessage:deletesuccessful').replace('__replace__', t('user')),
      );
    } catch (error) {
      showErrorNotification(`Something went wrong removing user: ${error}`);
    }
  }

  function getReactSelectUserOptions() {
    const users = Object.values(user.byId).filter(
      (user) => user.userrole !== CUSTOMER_USER,
    );

    return users.map(({ firstName, lastName, userName }) => ({
      label: `${firstName || ''} ${lastName || ''} (${userName})`.trim(),
      value: userName,
    }));
  }

  function getReactSelectCurrentUser(userName?: string) {
    if (!userName || !user.byId[userName]) {
      return;
    }

    const { firstName, lastName } = user.byId[userName];

    return {
      label: `${firstName} ${lastName}`,
      value: userName,
    };
  }

  function getReactSelectUsers(userNames?: string[]) {
    if (!userNames || !userNames.length) {
      return [];
    }

    if (!Array.isArray(userNames)) {
      userNames = [userNames];
    }

    return userNames
      .filter((u) => user.byId[u])
      .map((u) => {
        return {
          label: `${user.byId[u]?.firstName} ${user.byId[u]?.lastName}`.trim(),
          value: u,
        };
      });
  }

  return {
    getUsers,
    user,
    removeUser,
    getReactSelectUserOptions,
    getReactSelectCurrentUser,
    getReactSelectUsers,
  };
}
