import { createContext, useLayoutEffect, useState } from "react"
import { useTask } from "../hooks/useTask"

interface ApplicationContextType {
    taskActive: boolean
    taskId?: number

    setActiveTask: (id?: number) => void
}

const ApplicationStateContext = createContext<ApplicationContextType | undefined>(undefined)

function ApplicationStateProvider({ children }: { children: React.ReactNode }) {
    const [activeTask, setActiveTask] = useState<number | undefined>(undefined)
    const setActiveTaskFn = (id?: number) => { setActiveTask(id) }
    const { getActiveTasks } = useTask()

    useLayoutEffect(()=>{
        getActiveTasks().then((data)=> {
            if (data.length > 0) {
                setActiveTask(data[0].id)
            }
        })
    },[])

    const value = { taskActive: activeTask !== undefined, taskId: activeTask, setActiveTask: setActiveTaskFn }

    return <ApplicationStateContext.Provider value={value}>{children}</ApplicationStateContext.Provider>
}


export {
    ApplicationStateContext,
    ApplicationStateProvider
}