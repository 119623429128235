import { WorklistPageSetActionPayload, resetAllWorklistActivePagesAction, setWorklistActivePageAction, setWorklistActiveTabAction } from "../action/appStateActionFactory";
import { createReducer } from 'redux-act';
import produce from 'immer';

export interface AppStateReducerState {
    errors: {
        showModal: boolean,
        hasError: boolean,
        severity?: string,
        error?: string
    },
    settings: {
        pageSize: number
    },
    worklists: {
        activeTab: number,
        activePage: number
    }
};

const initialState: AppStateReducerState = {
    errors: {
        showModal: false,
        hasError: false,
    },
    settings: {
        pageSize: 25
    },
    worklists: {
        activeTab: 0,
        activePage: 1
    }
}


const appStateReducer = createReducer<AppStateReducerState>({}, initialState);
appStateReducer.on(resetAllWorklistActivePagesAction, (state) => produce(state, (draft) => {
    draft.worklists.activeTab = 0;
    draft.worklists.activePage = 1;
}));

appStateReducer.on(setWorklistActiveTabAction, (state: AppStateReducerState, payload: WorklistPageSetActionPayload) => produce(state, (draft) => {
    draft.worklists.activeTab = payload.index;
}));

appStateReducer.on(setWorklistActivePageAction, (state: AppStateReducerState, payload: WorklistPageSetActionPayload) => produce(state, (draft) => {
    draft.worklists.activePage = payload.index;
}));



export default appStateReducer;