import React from 'react';
import ErrorInformation from './ErrorInformation';
import { withRouter } from 'react-router';
import { RouteConfigComponentProps } from 'react-router-config';
import { withRecordError } from "aws-rum-react";

export type ErrorInfo = {
    componentStack: string;
    [key: string]: any;
};

type Props = {
    children: JSX.Element | JSX.Element[];
} & RouteConfigComponentProps & {
    recordError?: (error:unknown)=>void
};

type State = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends React.Component<Props, State> {


    protected recordError?: (error:unknown)=>void

    constructor(props: Props) {
        super(props);

        this.recordError = props.recordError

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    public componentDidCatch(
        error: Error | null = null,
        errorInfo: ErrorInfo | null = null
    ) {
        const normalizedError: Error = error
            ? error
            : new Error('Something unknown went wrong');

        if(this.recordError)
            this.recordError(normalizedError);

        this.setState(
            (currentState: State): State => ({
                ...currentState,
                error: normalizedError,
                errorInfo,
            })
        );
    }

    public componentDidUpdate() {
        if (!this.state.error) {
            return;
        }

        this.props.history.listen(() => {
            this.setState((currentState: State) => ({
                ...currentState,
                error: null,
                errorInfo: null,
            }));
        });
    }

    public render() {
        const { error, errorInfo } = this.state;

        if (error) {
            return <ErrorInformation error={error} errorInfo={errorInfo} />;
        }

        return this.props.children;
    }
}

//export default withRouter(ErrorBoundary);

export default withRecordError(withRouter(ErrorBoundary));
