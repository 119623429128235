import React, { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
    objectData: IQueryImage;
    onClick: (values: IQueryImageLightbox) => void;
}

export const ImageSearchResult: FC<Props> = ({ objectData, onClick }) => {
    return (
        <div
            className="react-autosuggest__suggestion-link"
            onClick={() => {
                onClick({
                    isOpen: true,
                    src: objectData.filename
                });
            }}
        >
            <LazyLoadImage
                alt={objectData.houtsoort}
                height={40}
                src={objectData.filename}
                width={40}
                wrapperClassName="react-autosuggest__suggestion-image"
            />
            <div>
                <div className="react-autosuggest__suggestion-type">Afbeelding</div>
                <div>{objectData.houtsoort} - {objectData.behandeling}</div>
            </div>
        </div>
    );
}
