import { FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import Nl from 'react-flags-icons/dist/Nl';
import De from 'react-flags-icons/dist/De';
import Us from 'react-flags-icons/dist/Us';
import Fr from 'react-flags-icons/dist/Fr';
import Es from 'react-flags-icons/dist/Es';
import Pl from 'react-flags-icons/dist/Pl';

type LanguageDialogProps = {
  visible: boolean;
  onHide: () => void;
  language?: string;
};

const LanguageDialog: FC<LanguageDialogProps> = (props) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { code: 'nl', flag: 'nl', description: 'Nederlands', icon: <Nl /> },
    { code: 'en', flag: 'us', description: 'English', icon: <Us /> },
    { code: 'de', flag: 'de', description: 'Deutsch', icon: <De /> },
    { code: 'fr', flag: 'fr', description: 'Français', icon: <Fr /> },
    {
      code: 'pl',
      flag: 'pl',
      description: 'Polski (Praca w toku)',
      icon: <Pl />,
    },
    {
      code: 'es',
      flag: 'es',
      description: 'Espagnol (trabajo en progreso)',
      icon: <Es />,
    },
  ];

  const changeLanguage = (e: any) => {
    let value: any = e.value;
    if (value && value.code) {
      i18n.changeLanguage(value.code);
    }
  };

  const getLanguage = (code: string): any => {
    if (code) {
      let selLang = {};
      if ((selLang = languages.filter((x) => x.code === code))) {
        if (Array.isArray(selLang) && selLang.length > 0) return selLang[0];
      }
    }
  };

  const itemTemplate = (option: any) => {
    if (!option.code) {
      return option.description;
    } else {
      return (
        <div>
          {option.icon} {option.description}
        </div>
      );
    }
  };

  return (
    <Dialog
      header={t('common:changeLanguage')}
      onHide={props.onHide}
      visible={props.visible}
      className="w-full md:w-4"
      contentStyle={{ overflowY: 'unset' }}
      position='top'
    >
      <div className="mb-2">
        {t('common:changelanguagedialog.infotext')}
      </div>
      <Dropdown
        id="language"
        style={{ width: '100%' }}
        value={getLanguage(i18n.language)}
        tooltip={t('common:changeLanguage')}
        options={languages}
        optionLabel="description"
        onChange={changeLanguage}
        itemTemplate={itemTemplate}
      />
    </Dialog>
  );
};

export default LanguageDialog;
