import { take, call, put, fork } from 'redux-saga/effects';
import * as AWSCognito from 'amazon-cognito-identity-js';
import {
  fetchLoginState,
  failedFetchingLoginState,
  loggedIn,
  submitNewPassword,
  failedFetchingUser,
  fetchUser,
  newPasswordRequired,
  createLogoutAction,
  refreshTokenAction,
  tokenRefreshedAction,
  IRefreshTokenResult,
  loggedOut
} from '../action/authActionFactory';
import { createHomePath } from '../../routing/appUrlGenerator';
import { UserApi } from /* webpackChunkName: "user-api" */ '../../api/user';
import { showNotification } from '../../utils/notification';
import { getTenantConfiguration } from '../../utils/tenantConfiguration';
import { Configuration } from '../../api/shared/configuration';

export const getUserPool = (): AWSCognito.CognitoUserPool => {
  const tenantId = getTenantConfiguration().id;

  let poolData: AWSCognito.ICognitoUserPoolData = {
    // @ts-ignore
    UserPoolId: process.env[`REACT_APP_USER_POOL_ID_${tenantId}`],
    // @ts-ignore
    ClientId: process.env[`REACT_APP_CLIENT_ID_${tenantId}`],
  };

  let userPool = new AWSCognito.CognitoUserPool(poolData);
  return userPool;
};

export const getCognitoUser = (email: string) => {
  return new AWSCognito.CognitoUser({
    Username: email,
    Pool: getUserPool(),
  });
}

type getSessionResponse = {
  payload: {
    jwt: string | undefined
    exp: number | undefined
    user: { [key: string]: any }
  } | null,
  err: any
}

const getSession = (cognitoUser: AWSCognito.CognitoUser) =>
  new Promise<getSessionResponse>((resolve) => {
    cognitoUser.getSession((err: any, result: AWSCognito.CognitoUserSession) => {
      if (result) {
        cognitoUser.getUserAttributes((err, attrs: AWSCognito.CognitoUserAttribute[] | undefined) => {

          if (err) {
            resolve({ payload: null, err });
          } else {
            const payload: any = {};
            payload.user = {};
            attrs?.forEach(
              (attr) => (payload.user[attr.getName()] = attr.getValue()),
            );
            var idToken = result.getIdToken();
            payload.jwt = idToken.getJwtToken();
            payload.exp = idToken.getExpiration();
            resolve({ payload, err: null });
          }
        });
      } else {
        resolve({ payload: null, err });
      }
    });
  });

const getUserDbDetails = (cognitoUser: AWSCognito.CognitoUser, sessionData: any) =>
  new Promise((resolve) => {
    let userApi = new UserApi(new Configuration({
      basePath: process.env.REACT_APP_SERVICE_URL_USER,
      accessToken: sessionData.jwt,
    }));

    userApi
      .getUserByName(cognitoUser.getUsername())
      .then((userDataResponse) => {
        if (userDataResponse && userDataResponse.status === 200) {
          sessionData.userDbData = userDataResponse.data;
        }
      })
      .finally(() => {
        resolve({ payload: sessionData });
      });
  });

const cognitoConfirmPassword = (params: any) => {
  return new Promise((resolve) => {
    const {
      user,
      password
    }: { user: AWSCognito.CognitoUser; password: string } = params;

    user.completeNewPasswordChallenge(
      password,
      {},
      {
        onSuccess: (result: AWSCognito.CognitoUserSession) => {
          user.getUserAttributes((_err, attrs) => {
            const payload: any = {};
            attrs?.forEach(
              (attr) => (payload[attr.getName()] = attr.getValue()),
            );
            payload.jwt = result.getIdToken().getJwtToken();
            resolve({ payload });
          });
        },
        onFailure: (err: any) => {
          resolve({ payload: null, err });
        },
      },
    );
  });
}

const cognitoSignIn = (params: any) =>
  new Promise((resolve) => {
    const { email, password } = params;
    const authenticationDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = getCognitoUser(email);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getUserAttributes((_err, attrs) => {
          const payload: any = {};
          attrs?.forEach((attr) => (payload[attr.getName()] = attr.getValue()));
          payload.jwt = result.getIdToken().getJwtToken();
          payload.exp = result.getIdToken().getExpiration();
          resolve({ payload });
        });
      },
      onFailure: (err) => {
        //showErrorNotification(err.message);
        resolve({ payload: null, err });
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        resolve({
          payload: {
            user: cognitoUser,
            state: 'newPasswordRequired',
            data: { userAttributes, requiredAttributes },
          },
          err: null,
        });
      },
    });
  });

const refreshToken = (cognitoUser: AWSCognito.CognitoUser) => new Promise<{ session: null, err: any } | { session: AWSCognito.CognitoUserSession, err: null }>((resolve) => {
  cognitoUser.getSession((err: any, result?: AWSCognito.CognitoUserSession) => {
    if (result) {
      const token = result.getRefreshToken()

      cognitoUser.refreshSession(token, (err2?: any, result2?: any) => {
        if (result2) {
          resolve({ session: result2, err: null })
        } else {
          resolve({ session: null, err: err2 })
        }
      })

    } else {
      resolve({ session: null, err })
    }
  })
})

const getAccessToken = (cognitoUser: AWSCognito.CognitoUser) =>
  new Promise((resolve) => {
    cognitoUser.getSession((err: any, result: any) => {
      if (result) {
        const token = result.getAccessToken().getJwtToken();
        resolve({ token });
      } else {
        resolve({ token: null, err });
      }
    });
  });

const globalSignOut = (cognitoUser: AWSCognito.CognitoUser) =>
  new Promise((resolve) => {
    cognitoUser.globalSignOut({
      onSuccess: (result) => {
        resolve({ result });
      },
      onFailure: (err) => {
        resolve({ result: null, err });
      },
    });
  });

export function* handleFetchLoginState() {
  while (true) {
    const action = yield take(fetchLoginState);

    let userPool: AWSCognito.CognitoUserPool | undefined;
    try {
      userPool = getUserPool();
    } catch {
      yield put(failedFetchingLoginState(''));
      continue;
    }

    const cognitoUser = userPool?.getCurrentUser();

    if (cognitoUser) {
      let getSessionResult = yield call(getSession, cognitoUser);

      if (getSessionResult.payload && !getSessionResult.err) {
        let { payload, err } = yield call(
          getUserDbDetails,
          cognitoUser,
          getSessionResult.payload,
        );

        if (payload && !err) {
          yield put(loggedIn(Object.assign({}, payload, action.payload)));
          continue;
        }
      }

      yield put(failedFetchingLoginState(action.payload));
      continue;
    }
    yield put(failedFetchingLoginState(''));
  }
}


export function* authSaga() {
  yield fork(handleFetchLoginState);
  yield fork(handleLogin);
  yield fork(handleLogout);
  yield fork(handleNewPasswordConfirmation);
  yield fork(handleRefreshTokenRequest);
}



export function* handleRefreshTokenRequest() {
  while (true) {
    yield take(`${refreshTokenAction}`)

    let userPool: AWSCognito.CognitoUserPool;
    try {
      userPool = getUserPool();
    } catch {
      continue;
    }

    const cognitoUser = userPool?.getCurrentUser();

    if (cognitoUser) {
      const {session, err} = yield call(refreshToken, cognitoUser)

      if (!err) {

        let payload : IRefreshTokenResult = {
          exp: session.idToken.payload.exp,
          auth_time: session.idToken.payload.auth_time,
          iat: session.idToken.payload.iat,
          jwt: session.idToken.jwtToken
        }

        yield put(tokenRefreshedAction(payload))
      }
    }
  }
}

export function* handleLogout() {
  while (true) {
    yield take(`${createLogoutAction}`);

    let userPool;
    try {
      userPool = getUserPool();
    } catch {
      continue;
    }

    const cognitoUser = userPool?.getCurrentUser();

    if (cognitoUser) {
      const { token, err } = yield call(getAccessToken, cognitoUser);

      if (token && !err) {
        const { result, err } = yield call(globalSignOut, cognitoUser);
        if (result && !err) {
          yield put(loggedOut());
        }
      }
    }
  }
}

export function* handleNewPasswordConfirmation() {
  while (true) {
    const action = yield take(`${submitNewPassword}`);
    const { email, password } = action.payload;

    if (email && password) {
      const { payload, err } = yield call(
        cognitoConfirmPassword,
        action.payload,
      );

      if (!payload && err) {
        yield put(failedFetchingUser(`${err.statusCode}: ${err.message}`));
        //showErrorNotification(err.message);
        continue;
      }

      showNotification('New password set successfully');
      continue;
    }
    yield put(failedFetchingUser('Please set email/username and password'));
  }
}

export function* handleLogin() {
  while (true) {
    const action = yield take(fetchUser);
    const { email, password } = action.payload;

    if (email && password) {
      const { payload, err } = yield call(cognitoSignIn, action.payload);

      if (!payload && err) {
        yield put(failedFetchingUser(`${err.statusCode}: ${err.message}`));
        //showErrorNotification(err.message);
        continue;
      }

      if (payload.state === 'newPasswordRequired') {
        yield put(newPasswordRequired(payload));
        continue;
      }

      yield put(
        loggedIn({
          ...payload,
          pathname: createHomePath(),
          exp: payload.exp
        }),
      );
      continue;
    }
    yield put(failedFetchingUser('Please set email/username and password'));
  }
}
