/* tslint:disable */
/* eslint-disable */
/**
 * Tenant Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface MutationResult
 */
export interface MutationResult {
    /**
     * 
     * @type {boolean}
     * @memberof MutationResult
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof MutationResult
     */
    'message'?: string;
}
/**
 * Body for the new tenant request
 * @export
 * @interface NewTenantRequestBody
 */
export interface NewTenantRequestBody {
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'accountName': string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'contactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'contactLastName': string;
    /**
     * 
     * @type {Tier}
     * @memberof NewTenantRequestBody
     */
    'tier'?: Tier;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'contactEmail': string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'debtorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'userName': string;
    /**
     * 
     * @type {boolean}
     * @memberof NewTenantRequestBody
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    'address'?: string;
}
/**
 * Full Tenant Data
 * @export
 * @interface TenantData
 */
export interface TenantData {
    /**
     * Identifier by which the tenant is known in the system
     * @type {string}
     * @memberof TenantData
     */
    'id'?: string;
    /**
     * Name of the tenant
     * @type {string}
     * @memberof TenantData
     */
    'name'?: string;
    /**
     * 
     * @type {Tier}
     * @memberof TenantData
     */
    'tier'?: Tier;
    /**
     * Is tenant currently active
     * @type {boolean}
     * @memberof TenantData
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'IdentityPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'UserPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'systemAdminPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'systemAdminRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'systemSupportPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'systemSupportRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'trustRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'contactEmail': string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'contactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'contactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'debtorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantData
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface TenantDataAllOf
 */
export interface TenantDataAllOf {
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'contactEmail': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'contactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'contactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'debtorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDataAllOf
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface TenantSetting
 */
export interface TenantSetting {
    /**
     * Integer ID used as setting identifier
     * @type {number}
     * @memberof TenantSetting
     */
    'id'?: number;
    /**
     * String containing the subsystem the setting is for
     * @type {string}
     * @memberof TenantSetting
     */
    'subsystem': string;
    /**
     * Name of the setting
     * @type {string}
     * @memberof TenantSetting
     */
    'settingName': string;
    /**
     * arbitrary value for the given setting
     * @type {any}
     * @memberof TenantSetting
     */
    'settingValue'?: any;
}
/**
 * Summarized tenant information
 * @export
 * @interface TenantSummary
 */
export interface TenantSummary {
    /**
     * Identifier by which the tenant is known in the system
     * @type {string}
     * @memberof TenantSummary
     */
    'id'?: string;
    /**
     * Name of the tenant
     * @type {string}
     * @memberof TenantSummary
     */
    'name'?: string;
    /**
     * 
     * @type {Tier}
     * @memberof TenantSummary
     */
    'tier'?: Tier;
    /**
     * Is tenant currently active
     * @type {boolean}
     * @memberof TenantSummary
     */
    'active'?: boolean;
}
/**
 * Tenant Summary with system data
 * @export
 * @interface TenantSystemSummary
 */
export interface TenantSystemSummary {
    /**
     * Identifier by which the tenant is known in the system
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'id'?: string;
    /**
     * Name of the tenant
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'name'?: string;
    /**
     * 
     * @type {Tier}
     * @memberof TenantSystemSummary
     */
    'tier'?: Tier;
    /**
     * Is tenant currently active
     * @type {boolean}
     * @memberof TenantSystemSummary
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'IdentityPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'UserPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'systemAdminPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'systemAdminRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'systemSupportPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'systemSupportRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummary
     */
    'trustRole'?: string;
}
/**
 * 
 * @export
 * @interface TenantSystemSummaryAllOf
 */
export interface TenantSystemSummaryAllOf {
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'IdentityPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'UserPoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'systemAdminPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'systemAdminRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'systemSupportPolicy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'systemSupportRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSystemSummaryAllOf
     */
    'trustRole'?: string;
}
/**
 * Tier:\\n\\nB - Basic \\n1 - Level 1\\n2 - Level 2\\n3 - Level 3\\n4 - Level 4\\n5 - Level 5\\nF - Full
 * @export
 * @enum {string}
 */

export const Tier = {
    B: 'B',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    F: 'F',
    System: 'system'
} as const;

export type Tier = typeof Tier[keyof typeof Tier];



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new tenant on the system and sets up the primary (admin) user
         * @summary Create a new tenant on the scope system
         * @param {NewTenantRequestBody} [newTenantRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (newTenantRequestBody?: NewTenantRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTenantRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tenant details of the tenant identified by the supplied id
         * @summary Gets the details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantFull: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantFull', 'tenantId', tenantId)
            const localVarPath = `/tenants/tenant/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get tenant summary and system details of the tenant identified by the supplied id
         * @summary Gets the summary and system details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSystem: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getTenantSystem', 'tenantId', tenantId)
            const localVarPath = `/tenants/tenant/{tenantId}/system-only`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of registered tenants from the system
         * @summary Returns a list of available tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove the tenant identified by the supplied id
         * @summary remove a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTenant: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('removeTenant', 'tenantId', tenantId)
            const localVarPath = `/tenants/tenant/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tenant details of the tenant identified by the supplied id
         * @summary Update the details for a specific tenant
         * @param {string} tenantId 
         * @param {TenantData} [tenantData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (tenantId: string, tenantData?: TenantData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateTenant', 'tenantId', tenantId)
            const localVarPath = `/tenants/tenant/{tenantId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new tenant on the system and sets up the primary (admin) user
         * @summary Create a new tenant on the scope system
         * @param {NewTenantRequestBody} [newTenantRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(newTenantRequestBody?: NewTenantRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(newTenantRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tenant details of the tenant identified by the supplied id
         * @summary Gets the details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantFull(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantFull(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get tenant summary and system details of the tenant identified by the supplied id
         * @summary Gets the summary and system details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantSystem(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantSystemSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantSystem(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a list of registered tenants from the system
         * @summary Returns a list of available tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTenants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTenants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * remove the tenant identified by the supplied id
         * @summary remove a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTenant(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTenant(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tenant details of the tenant identified by the supplied id
         * @summary Update the details for a specific tenant
         * @param {string} tenantId 
         * @param {TenantData} [tenantData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(tenantId: string, tenantData?: TenantData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenant(tenantId, tenantData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * Creates a new tenant on the system and sets up the primary (admin) user
         * @summary Create a new tenant on the scope system
         * @param {NewTenantRequestBody} [newTenantRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(newTenantRequestBody?: NewTenantRequestBody, options?: any): AxiosPromise<TenantData> {
            return localVarFp.createTenant(newTenantRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Get tenant details of the tenant identified by the supplied id
         * @summary Gets the details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantFull(tenantId: string, options?: any): AxiosPromise<TenantData> {
            return localVarFp.getTenantFull(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get tenant summary and system details of the tenant identified by the supplied id
         * @summary Gets the summary and system details for a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantSystem(tenantId: string, options?: any): AxiosPromise<TenantSystemSummary> {
            return localVarFp.getTenantSystem(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of registered tenants from the system
         * @summary Returns a list of available tenants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTenants(options?: any): AxiosPromise<Array<TenantSummary>> {
            return localVarFp.listTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * remove the tenant identified by the supplied id
         * @summary remove a specific tenant
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTenant(tenantId: string, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.removeTenant(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update tenant details of the tenant identified by the supplied id
         * @summary Update the details for a specific tenant
         * @param {string} tenantId 
         * @param {TenantData} [tenantData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(tenantId: string, tenantData?: TenantData, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.updateTenant(tenantId, tenantData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * Creates a new tenant on the system and sets up the primary (admin) user
     * @summary Create a new tenant on the scope system
     * @param {NewTenantRequestBody} [newTenantRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(newTenantRequestBody?: NewTenantRequestBody, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTenant(newTenantRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tenant details of the tenant identified by the supplied id
     * @summary Gets the details for a specific tenant
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantFull(tenantId: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantFull(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get tenant summary and system details of the tenant identified by the supplied id
     * @summary Gets the summary and system details for a specific tenant
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenantSystem(tenantId: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenantSystem(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of registered tenants from the system
     * @summary Returns a list of available tenants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public listTenants(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).listTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove the tenant identified by the supplied id
     * @summary remove a specific tenant
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public removeTenant(tenantId: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).removeTenant(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tenant details of the tenant identified by the supplied id
     * @summary Update the details for a specific tenant
     * @param {string} tenantId 
     * @param {TenantData} [tenantData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(tenantId: string, tenantData?: TenantData, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenant(tenantId, tenantData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantSettingsApi - axios parameter creator
 * @export
 */
export const TenantSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create one or more system settings for the tenant identified by the supplied id
         * @summary Create system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings: async (tenantSetting?: Array<TenantSetting>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the system settings of the tenant identified by the supplied id
         * @summary Gets the system settings for a specific tenant
         * @param {string} [subsys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (subsys?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subsys !== undefined) {
                localVarQueryParameter['_subsys'] = subsys;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove system settings of the tenant identified by the supplied id
         * @summary Remove system settings for a specific tenant
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSettings: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one or more system settings for the tenant identified by the supplied id
         * @summary Update system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (tenantSetting?: Array<TenantSetting>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tenants/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantSettingsApi - functional programming interface
 * @export
 */
export const TenantSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create one or more system settings for the tenant identified by the supplied id
         * @summary Create system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSettings(tenantSetting?: Array<TenantSetting>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSettings(tenantSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the system settings of the tenant identified by the supplied id
         * @summary Gets the system settings for a specific tenant
         * @param {string} [subsys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(subsys?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantSetting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(subsys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove system settings of the tenant identified by the supplied id
         * @summary Remove system settings for a specific tenant
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSettings(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSettings(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one or more system settings for the tenant identified by the supplied id
         * @summary Update system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(tenantSetting?: Array<TenantSetting>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MutationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(tenantSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantSettingsApi - factory interface
 * @export
 */
export const TenantSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantSettingsApiFp(configuration)
    return {
        /**
         * Create one or more system settings for the tenant identified by the supplied id
         * @summary Create system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettings(tenantSetting?: Array<TenantSetting>, options?: any): AxiosPromise<object> {
            return localVarFp.createSettings(tenantSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the system settings of the tenant identified by the supplied id
         * @summary Gets the system settings for a specific tenant
         * @param {string} [subsys] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(subsys?: string, options?: any): AxiosPromise<Array<TenantSetting>> {
            return localVarFp.getSettings(subsys, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove system settings of the tenant identified by the supplied id
         * @summary Remove system settings for a specific tenant
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSettings(requestBody?: Array<number>, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.removeSettings(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Update one or more system settings for the tenant identified by the supplied id
         * @summary Update system settings for a specific tenant
         * @param {Array<TenantSetting>} [tenantSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(tenantSetting?: Array<TenantSetting>, options?: any): AxiosPromise<MutationResult> {
            return localVarFp.updateSettings(tenantSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantSettingsApi - object-oriented interface
 * @export
 * @class TenantSettingsApi
 * @extends {BaseAPI}
 */
export class TenantSettingsApi extends BaseAPI {
    /**
     * Create one or more system settings for the tenant identified by the supplied id
     * @summary Create system settings for a specific tenant
     * @param {Array<TenantSetting>} [tenantSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public createSettings(tenantSetting?: Array<TenantSetting>, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).createSettings(tenantSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the system settings of the tenant identified by the supplied id
     * @summary Gets the system settings for a specific tenant
     * @param {string} [subsys] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public getSettings(subsys?: string, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).getSettings(subsys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove system settings of the tenant identified by the supplied id
     * @summary Remove system settings for a specific tenant
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public removeSettings(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).removeSettings(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one or more system settings for the tenant identified by the supplied id
     * @summary Update system settings for a specific tenant
     * @param {Array<TenantSetting>} [tenantSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantSettingsApi
     */
    public updateSettings(tenantSetting?: Array<TenantSetting>, options?: AxiosRequestConfig) {
        return TenantSettingsApiFp(this.configuration).updateSettings(tenantSetting, options).then((request) => request(this.axios, this.basePath));
    }
}


