import { CSSProperties, FC, ReactElement, ReactNode, StyleHTMLAttributes } from 'react';
import classnames from 'classnames';
import { MdKeyboardBackspace } from '@react-icons/all-files/md/MdKeyboardBackspace';
import { useTranslation } from 'react-i18next';

type Props = {
    icon?: ReactNode;
    title?: string | ReactElement;
    actions?: ReactElement | boolean;
    className?: string;
    description?: ReactElement;
    style?: CSSProperties;
    onBackClick?: () => void;
    smallTitle?: boolean;
    children?: ReactNode
}

const Island: FC<Props> = ({ title, actions, className, description, children, style, onBackClick, smallTitle, icon}) => {
    const islandClassName = classnames('island', className);
    const { t } = useTranslation('common');

    return (
        <div className={islandClassName} style={style}>
            {onBackClick && (
                <button onClick={onBackClick} className="island-back-link">
                    <MdKeyboardBackspace />{t('back-to-overview')}
                </button>
            )}
            
            {(title || actions || description) && (
                <div className="island-header w-full flex-nowrap">
                    {title && (<div className="flex flex-row w-full align-items-center" >
                        {icon && (
                            <div className="island-icon mr-3">{icon}</div>
                        )}
                        {(typeof title  != "string") ? title : 
                        <h2 className={`island-title${smallTitle ? ' island-title-small' : ''} mb-0`}>{title}</h2>
                        }
                        </div>
                    )}
                    {actions && (
                        <div className="actions-holder flex-none">
                            {actions}
                        </div>
                    )}
                    {description && (
                        <div className="island-description">
                            {description}
                        </div>
                    )}
                </div>
            )}
            {children}
        </div>
    );
}

export default Island;
