import React, { FC } from 'react';
import { Field, useField } from 'formik';

type Props = {
  name: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  doFocus?: (e: any) => void;
  doBlur?: (e: any) => void;
  summaryMode?: boolean;
  max?:number;
  min?:number;
  step?:number;
  className?:string;
  tooltip?:string;
};

const InputText: FC<Props> = ({
  name,
  type,
  placeholder,
  disabled,
  doFocus,
  doBlur,
  summaryMode = false,
  max,
  min, 
  step, 
  className,
}) => {
  const [fieldProps] = useField(name);

  if (summaryMode) {
    let v = fieldProps.value;
    if (type === 'number') {
      v = Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: true,
      }).format(fieldProps.value);
    }
    return <span className="summary-mode">{v}</span>;
  }

  const props = {} as HTMLInputElement;

  if (type === 'number') {
    props.step = step?.toString() ?? '.01';
    if (max !== undefined) 
      props.max = max.toString()
    if (min !== undefined)
      props.min = min.toString()
  }

  return (
    <Field
      {...props}
      name={name}
      className={`p-inputtext p-component form-control ${className}`}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      onFocus={doFocus}
      onBlur={doBlur}
    />
  );
};

export default InputText;
