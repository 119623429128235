/* tslint:disable */
/* eslint-disable */
/**
 * User Management API
 * This API facilitates the creation, modification and/or removal of users on the system.
 *
 * The version of the OpenAPI document: 2.0.2
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActiveRate
 */
export interface ActiveRate {
    /**
     * 
     * @type {number}
     * @memberof ActiveRate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActiveRate
     */
    'userName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActiveRate
     */
    'hourlyRate': number;
}
/**
 * 
 * @export
 * @interface AdminSetPassword
 */
export interface AdminSetPassword {
    /**
     * 
     * @type {string}
     * @memberof AdminSetPassword
     */
    'newPassword'?: string;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface NewRate
 */
export interface NewRate {
    /**
     * 
     * @type {number}
     * @memberof NewRate
     */
    'rate': number | null;
    /**
     * 
     * @type {string}
     * @memberof NewRate
     */
    'starts'?: string;
}
/**
 * 
 * @export
 * @interface StatusMessage
 */
export interface StatusMessage {
    /**
     * 
     * @type {string}
     * @memberof StatusMessage
     */
    'status': string;
}
/**
 * System admin user
 * @export
 * @interface SystemUserData
 */
export interface SystemUserData {
    /**
     * 
     * @type {string}
     * @memberof SystemUserData
     */
    'tenant_id'?: string;
    /**
     * 
     * @type {UserData}
     * @memberof SystemUserData
     */
    'userdata'?: UserData;
}
/**
 * User data
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'postalcode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'role': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserData
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'confirmedStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'userrole'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'customerId'?: string;
}
/**
 * 
 * @export
 * @interface UserRate
 */
export interface UserRate {
    /**
     * 
     * @type {number}
     * @memberof UserRate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRate
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof UserRate
     */
    'hourlyRate': number | null;
    /**
     * 
     * @type {string}
     * @memberof UserRate
     */
    'startDate'?: string;
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reset user password
         * @param {string} name 
         * @param {AdminSetPassword} [adminSetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetPassword: async (name: string, adminSetPassword?: AdminSetPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('adminResetPassword', 'name', name)
            const localVarPath = `/users/user/{name}/resetPassword`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provision a new system admin user
         * @summary Create SYSTEM user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystemAdminUser: async (systemUserData?: SystemUserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user/system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new tenant admin user
         * @summary Create tenant admin user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantAdminUser: async (systemUserData?: SystemUserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user/reg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user in the active tenant context
         * @summary Create new user
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userData?: UserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user (from active tenant)
         * @summary Delete user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteUserName', 'name', name)
            const localVarPath = `/users/user/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of assignable users (in the active tenant context)
         * @summary Get list of assignable users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/assignable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves user details for the given user (in the active tenant context)
         * @summary Get user details
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getUserByName', 'name', name)
            const localVarPath = `/users/user/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get result if user exists
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExists: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getUserExists', 'name', name)
            const localVarPath = `/users/user/{name}/exists`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve userpool for given user
         * @summary Get userpool for user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPoolByUsername: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getUserPoolByUsername', 'name', name)
            const localVarPath = `/users/user/{name}/pool`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tenantHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Tenant-Id", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of available users (in the active tenant context)
         * @summary Get list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the ACTIVE hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        perUserGetActiveRates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/rates/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        perUserGetRates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/rates/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set user hourly rate
         * @summary Set hourly rate
         * @param {string} name 
         * @param {NewRate} [newRate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserHourlyRate: async (name: string, newRate?: NewRate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('setUserHourlyRate', 'name', name)
            const localVarPath = `/users/user/{name}/rate`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user details
         * @summary Update user data
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userData?: UserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disable the given user
         * @summary disable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDisable: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDisable', 'id', id)
            const localVarPath = `/users/user/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable the given user
         * @summary Enable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEnable: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userEnable', 'id', id)
            const localVarPath = `/users/user/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearertoken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reset user password
         * @param {string} name 
         * @param {AdminSetPassword} [adminSetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminResetPassword(name: string, adminSetPassword?: AdminSetPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminResetPassword(name, adminSetPassword, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.adminResetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Provision a new system admin user
         * @summary Create SYSTEM user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSystemAdminUser(systemUserData?: SystemUserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSystemAdminUser(systemUserData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createSystemAdminUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Create a new tenant admin user
         * @summary Create tenant admin user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenantAdminUser(systemUserData?: SystemUserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenantAdminUser(systemUserData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createTenantAdminUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a new user in the active tenant context
         * @summary Create new user
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userData?: UserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Delete user (from active tenant)
         * @summary Delete user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserName(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.deleteUserName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of assignable users (in the active tenant context)
         * @summary Get list of assignable users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignableUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignableUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getAssignableUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieves user details for the given user (in the active tenant context)
         * @summary Get user details
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByName(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUserByName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get result if user exists
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserExists(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserExists(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUserExists']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Retrieve userpool for given user
         * @summary Get userpool for user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPoolByUsername(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPoolByUsername(name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUserPoolByUsername']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns a list of available users (in the active tenant context)
         * @summary Get list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get the ACTIVE hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async perUserGetActiveRates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActiveRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.perUserGetActiveRates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.perUserGetActiveRates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * get the hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async perUserGetRates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.perUserGetRates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.perUserGetRates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * set user hourly rate
         * @summary Set hourly rate
         * @param {string} name 
         * @param {NewRate} [newRate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserHourlyRate(name: string, newRate?: NewRate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserHourlyRate(name, newRate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.setUserHourlyRate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Update user details
         * @summary Update user data
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userData?: UserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Disable the given user
         * @summary disable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDisable(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDisable(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userDisable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Enable the given user
         * @summary Enable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userEnable(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userEnable(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.userEnable']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Reset user password
         * @param {string} name 
         * @param {AdminSetPassword} [adminSetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminResetPassword(name: string, adminSetPassword?: AdminSetPassword, options?: any): AxiosPromise<void> {
            return localVarFp.adminResetPassword(name, adminSetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Provision a new system admin user
         * @summary Create SYSTEM user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystemAdminUser(systemUserData?: SystemUserData, options?: any): AxiosPromise<void> {
            return localVarFp.createSystemAdminUser(systemUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new tenant admin user
         * @summary Create tenant admin user
         * @param {SystemUserData} [systemUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenantAdminUser(systemUserData?: SystemUserData, options?: any): AxiosPromise<object> {
            return localVarFp.createTenantAdminUser(systemUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user in the active tenant context
         * @summary Create new user
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userData?: UserData, options?: any): AxiosPromise<UserData> {
            return localVarFp.createUser(userData, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user (from active tenant)
         * @summary Delete user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserName(name: string, options?: any): AxiosPromise<StatusMessage> {
            return localVarFp.deleteUserName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of assignable users (in the active tenant context)
         * @summary Get list of assignable users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignableUsers(options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getAssignableUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves user details for the given user (in the active tenant context)
         * @summary Get user details
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByName(name: string, options?: any): AxiosPromise<UserData> {
            return localVarFp.getUserByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get result if user exists
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExists(name: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.getUserExists(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve userpool for given user
         * @summary Get userpool for user
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPoolByUsername(name: string, options?: any): AxiosPromise<object> {
            return localVarFp.getUserPoolByUsername(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of available users (in the active tenant context)
         * @summary Get list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * get the ACTIVE hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        perUserGetActiveRates(options?: any): AxiosPromise<Array<ActiveRate>> {
            return localVarFp.perUserGetActiveRates(options).then((request) => request(axios, basePath));
        },
        /**
         * get the hourly rates table
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        perUserGetRates(options?: any): AxiosPromise<Array<UserRate>> {
            return localVarFp.perUserGetRates(options).then((request) => request(axios, basePath));
        },
        /**
         * set user hourly rate
         * @summary Set hourly rate
         * @param {string} name 
         * @param {NewRate} [newRate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserHourlyRate(name: string, newRate?: NewRate, options?: any): AxiosPromise<void> {
            return localVarFp.setUserHourlyRate(name, newRate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user details
         * @summary Update user data
         * @param {UserData} [userData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userData?: UserData, options?: any): AxiosPromise<UserData> {
            return localVarFp.updateUser(userData, options).then((request) => request(axios, basePath));
        },
        /**
         * Disable the given user
         * @summary disable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDisable(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userDisable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable the given user
         * @summary Enable user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEnable(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userEnable(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Reset user password
     * @param {string} name 
     * @param {AdminSetPassword} [adminSetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminResetPassword(name: string, adminSetPassword?: AdminSetPassword, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).adminResetPassword(name, adminSetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provision a new system admin user
     * @summary Create SYSTEM user
     * @param {SystemUserData} [systemUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createSystemAdminUser(systemUserData?: SystemUserData, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createSystemAdminUser(systemUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new tenant admin user
     * @summary Create tenant admin user
     * @param {SystemUserData} [systemUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createTenantAdminUser(systemUserData?: SystemUserData, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createTenantAdminUser(systemUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user in the active tenant context
     * @summary Create new user
     * @param {UserData} [userData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userData?: UserData, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(userData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user (from active tenant)
     * @summary Delete user
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserName(name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of assignable users (in the active tenant context)
     * @summary Get list of assignable users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAssignableUsers(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getAssignableUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves user details for the given user (in the active tenant context)
     * @summary Get user details
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserByName(name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get result if user exists
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserExists(name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserExists(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve userpool for given user
     * @summary Get userpool for user
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserPoolByUsername(name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserPoolByUsername(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of available users (in the active tenant context)
     * @summary Get list of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the ACTIVE hourly rates table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public perUserGetActiveRates(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).perUserGetActiveRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the hourly rates table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public perUserGetRates(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).perUserGetRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set user hourly rate
     * @summary Set hourly rate
     * @param {string} name 
     * @param {NewRate} [newRate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public setUserHourlyRate(name: string, newRate?: NewRate, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).setUserHourlyRate(name, newRate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user details
     * @summary Update user data
     * @param {UserData} [userData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(userData?: UserData, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(userData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disable the given user
     * @summary disable user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDisable(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userDisable(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable the given user
     * @summary Enable user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userEnable(id: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userEnable(id, options).then((request) => request(this.axios, this.basePath));
    }
}



