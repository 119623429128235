/* tslint:disable */
/* eslint-disable */
/**
 * Form Management API
 * This API facilitates the creation, modification and/or removal of forms for tenants on the system.
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: contact@weijbv.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Form create request
 * @export
 * @interface FormCreate
 */
export interface FormCreate {
    /**
     * 
     * @type {string}
     * @memberof FormCreate
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FormCreate
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FormCreate
     */
    'description'?: string;
    /**
     * 
     * @type {object | Array<any>}
     * @memberof FormCreate
     */
    'formSpec'?: object | Array<any>;
}
/**
 * Form details including the form specification
 * @export
 * @interface FormDetails
 */
export interface FormDetails {
    /**
     * 
     * @type {string}
     * @memberof FormDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FormDetails
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FormDetails
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FormDetails
     */
    'description'?: string;
    /**
     * 
     * @type {object | Array<any>}
     * @memberof FormDetails
     */
    'formSpec'?: object | Array<any>;
}
/**
 * Summary information describing a form
 * @export
 * @interface FormSummary
 */
export interface FormSummary {
    /**
     * 
     * @type {string}
     * @memberof FormSummary
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FormSummary
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FormSummary
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FormSummary
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormSummary
     */
    'tenantId': string;
}
/**
 * 
 * @export
 * @interface FormUpdate
 */
export interface FormUpdate {
    /**
     * 
     * @type {object | Array<any>}
     * @memberof FormUpdate
     */
    'formSpec'?: object | Array<any>;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    'service'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    'isAlive'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse201
     */
    'id'?: string;
}

/**
 * FormApi - axios parameter creator
 * @export
 */
export const FormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new form
         * @summary Create new form
         * @param {FormCreate} [formCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewForm: async (formCreate?: FormCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forms/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFormId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFormId', 'id', id)
            const localVarPath = `/forms/form/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fetch form details for given form id
         * @summary Get Form Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFormById', 'id', id)
            const localVarPath = `/forms/form/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forms/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch a list of forms from the system  Returns only the forms that are allowed for the logged in tenant  
         * @summary Fetch a list of forms from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forms/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update form specification for given id
         * @summary Update form specification
         * @param {string} id 
         * @param {FormUpdate} [formUpdate] New form specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForm: async (id: string, formUpdate?: FormUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateForm', 'id', id)
            const localVarPath = `/forms/form/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormApi - functional programming interface
 * @export
 */
export const FormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormApiAxiosParamCreator(configuration)
    return {
        /**
         * Create new form
         * @summary Create new form
         * @param {FormCreate} [formCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewForm(formCreate?: FormCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewForm(formCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFormId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFormId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * fetch form details for given form id
         * @summary Get Form Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFormById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFormById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch a list of forms from the system  Returns only the forms that are allowed for the logged in tenant  
         * @summary Fetch a list of forms from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listForms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listForms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update form specification for given id
         * @summary Update form specification
         * @param {string} id 
         * @param {FormUpdate} [formUpdate] New form specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateForm(id: string, formUpdate?: FormUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateForm(id, formUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormApi - factory interface
 * @export
 */
export const FormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormApiFp(configuration)
    return {
        /**
         * Create new form
         * @summary Create new form
         * @param {FormCreate} [formCreate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewForm(formCreate?: FormCreate, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.createNewForm(formCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete form
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFormId(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFormId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch form details for given form id
         * @summary Get Form Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormById(id: string, options?: any): AxiosPromise<FormDetails> {
            return localVarFp.getFormById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of forms from the system  Returns only the forms that are allowed for the logged in tenant  
         * @summary Fetch a list of forms from the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listForms(options?: any): AxiosPromise<Array<FormSummary>> {
            return localVarFp.listForms(options).then((request) => request(axios, basePath));
        },
        /**
         * Update form specification for given id
         * @summary Update form specification
         * @param {string} id 
         * @param {FormUpdate} [formUpdate] New form specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateForm(id: string, formUpdate?: FormUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateForm(id, formUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormApi - object-oriented interface
 * @export
 * @class FormApi
 * @extends {BaseAPI}
 */
export class FormApi extends BaseAPI {
    /**
     * Create new form
     * @summary Create new form
     * @param {FormCreate} [formCreate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public createNewForm(formCreate?: FormCreate, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).createNewForm(formCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete form
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public deleteFormId(id: string, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).deleteFormId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fetch form details for given form id
     * @summary Get Form Details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public getFormById(id: string, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).getFormById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public getHealth(options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch a list of forms from the system  Returns only the forms that are allowed for the logged in tenant  
     * @summary Fetch a list of forms from the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public listForms(options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).listForms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update form specification for given id
     * @summary Update form specification
     * @param {string} id 
     * @param {FormUpdate} [formUpdate] New form specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public updateForm(id: string, formUpdate?: FormUpdate, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).updateForm(id, formUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


