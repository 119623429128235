import { useState } from 'react';

export const useModal = (initialState = false) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(initialState);

    function openModal() {
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    function toggleModal() {
        setModalIsOpen(!modalIsOpen);
    }

    return {
        modalIsOpen,
        setModalIsOpen,
        openModal,
        closeModal,
        toggleModal,
    };
};
