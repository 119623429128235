// tslint:disable
/**
 * Stock API
 * This API facilitates stockage data services
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: contact@vansloophout.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from '../shared/configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, UNKNOWN_BASE_TYPE, RequestArgs, BaseAPI, RequiredError } from '../shared/base';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    category?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    location?: string;
    /**
     *
     * @type {number}
     * @memberOf Article
     */
    procurementCost?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ArticleDetails
 */
export interface ArticleDetails {
    /**
     * 
     * @type {number}
     * @memberof ArticleDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    articleNumber?: string;
    /**
     * 
     * @type {Category}
     * @memberof ArticleDetails
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    location?: string;
    /**
     *
     * @type {number}
     * @memberOf Article
     */
    procurementCost?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDetails
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleDetails
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ArticlePost
 */
export interface ArticlePost {
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    articleNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlePost
     */
    category: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    location?: string;
    /**
     *
     * @type {number}
     * @memberOf Article
     */
    procurementCost?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePost
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlePost
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ArticlePut
 */
export interface ArticlePut {
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    articleNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlePut
     */
    category?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    location?: string;
    /**
     *
     * @type {number}
     * @memberOf Article
     */
    procurementCost?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePut
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticlePut
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface ArticleStockHistory
 */
export interface ArticleStockHistory {
    /**
     * 
     * @type {number}
     * @memberof ArticleStockHistory
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    articleNumber?: string;
    /**
     * 
     * @type {Category}
     * @memberof ArticleStockHistory
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleStockHistory
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleStockHistory
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface Categories
 */
export interface Categories {
    /**
     * 
     * @type {number}
     * @memberof Categories
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Categories
     */
    categoryName: string;
    /**
     * 
     * @type {number}
     * @memberof Categories
     */
    parentId: number;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Categories
     */
    subCategories?: Array<Category>;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Healthy
 */
export interface Healthy {
    /**
     * 
     * @type {string}
     * @memberof Healthy
     */
    service?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    isAlive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Healthy
     */
    isHealthy?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    articleNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject
     */
    category?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    location?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    unit?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject1
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject2
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    articleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    articleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    brandName?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    category?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    location?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    unit?: string;
}

/**
 * StockApi - axios parameter creator
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCategory.');
            }
            const localVarPath = `/stock/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get article details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArticle.');
            }
            const localVarPath = `/stock/article/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stock of article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleIdStock: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getArticleIdStock.');
            }
            const localVarPath = `/stock/article/{id}/stock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/categories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List sub categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryIdSubcategories: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCategoryIdSubcategories.');
            }
            const localVarPath = `/stock/category/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/articles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAndInsertCategory: async (inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/category/insert`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject2 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject2 !== undefined ? inlineObject2 : {}) : (inlineObject2 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new article
         * @param {ArticlePost} articlePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArticle: async (articlePost: ArticlePost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'articlePost' is not null or undefined
            if (articlePost === null || articlePost === undefined) {
                throw new RequiredError('articlePost','Required parameter articlePost was null or undefined when calling postArticle.');
            }
            const localVarPath = `/stock/article`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof articlePost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(articlePost !== undefined ? articlePost : {}) : (articlePost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory: async (inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/category`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update article details
         * @param {string} id 
         * @param {ArticlePut} articlePut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArticle: async (id: string, articlePut: ArticlePut, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putArticle.');
            }
            // verify required parameter 'articlePut' is not null or undefined
            if (articlePut === null || articlePut === undefined) {
                throw new RequiredError('articlePut','Required parameter articlePut was null or undefined when calling putArticle.');
            }
            const localVarPath = `/stock/article/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof articlePut !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(articlePut !== undefined ? articlePut : {}) : (articlePut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a new article
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArticles: async (requestBody?: Array<object>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/articles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update category
         * @param {object} id 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCategory: async (id: object, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling putCategory.');
            }
            const localVarPath = `/stock/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authToken required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof uNKNOWNBASETYPE !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(uNKNOWNBASETYPE !== undefined ? uNKNOWNBASETYPE : {}) : (uNKNOWNBASETYPE || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).deleteCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary get article details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDetails>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getArticle(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get stock of article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleIdStock(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleStockHistory>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getArticleIdStock(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getCategories(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List sub categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryIdSubcategories(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Categories>>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getCategoryIdSubcategories(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Healthy>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getHealth(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listArticles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Article>>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).listArticles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAndInsertCategory(inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).postAndInsertCategory(inlineObject2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create a new article
         * @param {ArticlePost} articlePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postArticle(articlePost: ArticlePost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).postArticle(articlePost, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategory(inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).postCategory(inlineObject1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update article details
         * @param {string} id 
         * @param {ArticlePut} articlePut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putArticle(id: string, articlePut: ArticlePut, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).putArticle(id, articlePut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary create a new article
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putArticles(requestBody?: Array<object>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).putArticles(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update category
         * @param {object} id 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCategory(id: object, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).putCategory(id, uNKNOWNBASETYPE, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StockApi - factory interface
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary delete category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: string, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get article details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(id: string, options?: any): AxiosPromise<ArticleDetails> {
            return StockApiFp(configuration).getArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stock of article
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleIdStock(id: string, options?: any): AxiosPromise<ArticleStockHistory> {
            return StockApiFp(configuration).getArticleIdStock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): AxiosPromise<Array<Category>> {
            return StockApiFp(configuration).getCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List sub categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryIdSubcategories(id: string, options?: any): AxiosPromise<Array<Categories>> {
            return StockApiFp(configuration).getCategoryIdSubcategories(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Determine service health
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Healthy> {
            return StockApiFp(configuration).getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List articles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listArticles(options?: any): AxiosPromise<Array<Article>> {
            return StockApiFp(configuration).listArticles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAndInsertCategory(inlineObject2?: InlineObject2, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).postAndInsertCategory(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new article
         * @param {ArticlePost} articlePost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postArticle(articlePost: ArticlePost, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).postArticle(articlePost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new category
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(inlineObject1?: InlineObject1, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).postCategory(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update article details
         * @param {string} id 
         * @param {ArticlePut} articlePut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArticle(id: string, articlePut: ArticlePut, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).putArticle(id, articlePut, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a new article
         * @param {Array<object>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArticles(requestBody?: Array<object>, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).putArticles(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update category
         * @param {object} id 
         * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCategory(id: object, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<void> {
            return StockApiFp(configuration).putCategory(id, uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - object-oriented interface
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI {
    /**
     * 
     * @summary delete category
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public deleteCategory(id: string, options?: any) {
        return StockApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get article details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getArticle(id: string, options?: any) {
        return StockApiFp(this.configuration).getArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stock of article
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getArticleIdStock(id: string, options?: any) {
        return StockApiFp(this.configuration).getArticleIdStock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getCategories(options?: any) {
        return StockApiFp(this.configuration).getCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List sub categories
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getCategoryIdSubcategories(id: string, options?: any) {
        return StockApiFp(this.configuration).getCategoryIdSubcategories(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Determine service health
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getHealth(options?: any) {
        return StockApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List articles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public listArticles(options?: any) {
        return StockApiFp(this.configuration).listArticles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new category
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postAndInsertCategory(inlineObject2?: InlineObject2, options?: any) {
        return StockApiFp(this.configuration).postAndInsertCategory(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new article
     * @param {ArticlePost} articlePost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postArticle(articlePost: ArticlePost, options?: any) {
        return StockApiFp(this.configuration).postArticle(articlePost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new category
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public postCategory(inlineObject1?: InlineObject1, options?: any) {
        return StockApiFp(this.configuration).postCategory(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update article details
     * @param {string} id 
     * @param {ArticlePut} articlePut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public putArticle(id: string, articlePut: ArticlePut, options?: any) {
        return StockApiFp(this.configuration).putArticle(id, articlePut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a new article
     * @param {Array<object>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public putArticles(requestBody?: Array<object>, options?: any) {
        return StockApiFp(this.configuration).putArticles(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update category
     * @param {object} id 
     * @param {UNKNOWN_BASE_TYPE} [uNKNOWNBASETYPE] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public putCategory(id: object, uNKNOWNBASETYPE?: UNKNOWN_BASE_TYPE, options?: any) {
        return StockApiFp(this.configuration).putCategory(id, uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

}


