import { createReducer } from 'redux-act';
import produce from 'immer';
import { OrderSummary, OrderDetails, OrderSummaryStateEnum, TimeLogItem, FullTimeLog } from '../../api/order';
import { fetchOrderSummaryAction, ApproveOrderPart, AssignUserToOrderParams, createApproveOrderPartAction, createAssignUsersToOrderAction, orderSummariesFetchedResult, createInUseByActiveUserAction, createSetDetailsAction, createSetDoneStateAction, createSetExternalDocumentIdAction, createSetFormValuesAction, createSetOrderStateAction, createSetSummaryAction, FullWorkLogOnOrderParams, ISetDoneState, ReceivedFullWorkLogOnOrderAction, SetExternalDocumentId, SetFormValuesAction, SetOrderState, setStopWorkingOnAction, SetStopWorkingOnParams, setWorkingOnAction, SetWorkingOnParams, FetchOrdersSummaryActionRequest, assignedOrderSummariesFetchedResult, orderTallyFetchedResult, OrderTally } from '../action/orderActionFactory';
import { createLogoutAction } from '../action/authActionFactory';

export type WorkingOn = {
    orderId: string;
    formId: string;
} & TimeLogItem;

export interface OrderSummarySorted extends OrderSummary {
    sortOrder: number
}

export interface OrderReducerState {
    summary: { [id: string]: OrderSummarySorted },
    summaryLoading: boolean,

    details: {
        [id: string]: OrderDetails
    },
    detailsLoading: boolean

    //raw: string[]
    inUse: OrderSummary[]
    assignedToMe: OrderSummary[]
    inUseOrAssignedLoading: boolean

    totalNrOfOrders?: number
    tally: {
        open: {
            planned: number,
            nearDeadline: number,
            pastDeadline: number,
            aftercare: number,
            totalWorktimeEstimation: number,
            delivery: number,
            readyForTransport: number
        },
        done: number,
        onhold: number,
        pendingpayment: number,
        assigned: {
            open: number,
            other: number,
            total: number
        },

        loading: boolean
        updatedAt?: number
    }

    workLog: {
        [orderId: string]: FullTimeLog
    }

    updatedAt?: number;
}

const initialState: OrderReducerState = {
    summary: {},
    summaryLoading: true,
    details: {},
    detailsLoading: false,
    //raw: [],
    inUse: [],
    assignedToMe: [],
    inUseOrAssignedLoading: true,

    tally: {
        open: {
            planned: 0,
            nearDeadline: 0,
            pastDeadline: 0,
            aftercare: 0,
            totalWorktimeEstimation: 0,
            delivery: 0,
            readyForTransport: 0
        },
        done: 0,
        onhold: 0,
        pendingpayment: 0,
        assigned: {
            open: 0,
            other: 0,
            total: 0
        },

        loading: true,
    },

    workLog: {}
};

export const orderReducer = createReducer<OrderReducerState>({}, initialState);


// SUMMARY
orderReducer.on(fetchOrderSummaryAction, (state: OrderReducerState, payload: FetchOrdersSummaryActionRequest) => produce(state, (draft) => {
    draft.details = {}
    draft.summaryLoading = true
    draft.updatedAt = undefined
}))

orderReducer.on(orderSummariesFetchedResult, (state: OrderReducerState, payload: OrderSummary[]) => produce(state, (draft) => {
    const orderIds = payload.map(order => order.id ?? null);
    // @ts-ignore -> ids are never null because we filter them here
    //draft.raw = orderIds.filter(id => id !== null);
    draft.summaryLoading = false
    draft.summary = {}

    if (payload.length === 0) {
        draft.summary = initialState.summary;
    } else {

        payload.forEach((order, sortidx) => {
            if (!order.id) {
                return;
            }

            let orderwithsortkey: OrderSummarySorted = { ...order, sortOrder: sortidx }
            draft.summary[order.id.toString()] = orderwithsortkey
        });
    }

    draft.updatedAt = Date.now();
}));

orderReducer.on(assignedOrderSummariesFetchedResult, (state: OrderReducerState, payload: [OrderSummary[], OrderSummary[]]) => {

    return produce(state, (draft) => {

        if (payload[0].length === 0) draft.inUse = []
        if (payload[1].length === 0) draft.assignedToMe = []

        if (payload[0].length > 0 || payload[1].length > 0) {
            draft.inUse = payload[0];
            draft.assignedToMe = payload[1];
        }
        draft.inUseOrAssignedLoading = false
    })
})

// END SUMMARY

// TALLY
orderReducer.on(orderTallyFetchedResult, (state: OrderReducerState, payload: OrderTally) => produce(state, (draft) => {

    draft.tally.open.aftercare = payload.open.aftercare;
    draft.tally.open.nearDeadline = payload.open.nearDeadline;
    draft.tally.open.pastDeadline = payload.open.pastDeadline;
    draft.tally.open.planned = payload.open.planned;
    draft.tally.open.delivery = payload.open.delivery;
    
    draft.tally.open.totalWorktimeEstimation = payload.open.totalWorktimeEstimation;
    draft.tally.open.readyForTransport = payload.open.readyForTransport;

    draft.totalNrOfOrders = payload.open.total

    draft.tally.done = payload.done
    draft.tally.assigned = payload.assigned
    draft.tally.onhold = payload.onhold
    draft.tally.pendingpayment = payload.pendingpayment

    draft.tally.updatedAt = Date.now()
    draft.tally.loading = false
}))
// END TALLY


orderReducer.on(createSetDetailsAction, (state: OrderReducerState, payload: OrderDetails) => produce(state, (draft) => {
    if (!payload.summary?.id) {
        return;
    }

    draft.summary[payload.summary.id] = {
        ...payload.summary,
        sortOrder: 0
    }

    draft.details[payload.summary.id] = {
        ...payload
    };
}));

orderReducer.on(createSetFormValuesAction, (state: OrderReducerState, payload: SetFormValuesAction) => produce(state, (draft) => {
    draft.details[payload.id].details = payload.values;
}));

orderReducer.on(createSetOrderStateAction, (state: OrderReducerState, payload: SetOrderState) => produce(state, (draft) => {
    if (draft.summary[payload.orderId].state === 'done' && payload.state !== 'done') {
        draft.summary[payload.orderId].progressData?.forEach(p => p.progressState === 'waiting');
    }

    draft.details[payload.orderId].summary!.state = payload.state;
    draft.summary[payload.orderId].state = payload.state;
    draft.summary[payload.orderId].id = Number.parseInt(payload.orderId);
}));

orderReducer.on(createApproveOrderPartAction, (state: OrderReducerState, payload: ApproveOrderPart) => produce(state, (draft) => {
    const order = draft.summary[payload.orderId];

    if (!order || !order.progressData) {
        throw new Error(`No order summary found with id ${payload.orderId}`);
    }

    const toApproveForm = order.progressData.find((data) => data.formId === payload.formId);

    if (!toApproveForm) {
        throw new Error('To approve form should be available at this point.');
    }

    toApproveForm.approvalState = 'approved';
}));

orderReducer.on(setStopWorkingOnAction, (state: OrderReducerState, payload: SetStopWorkingOnParams) => produce(state, (draft) => {
    const order = draft.summary[payload.orderId];

    const formProgressItem = order.progressData?.find((data) => data.formId === payload.formId);

    if (formProgressItem) {
        formProgressItem.progressState = payload.status;
    }

    const index = draft.inUse.findIndex(o => o.id?.toString() === payload.orderId);
    draft.inUse.splice(index, 1);
}));

orderReducer.on(createSetSummaryAction, (state: OrderReducerState, payload: OrderSummary) => produce(state, (draft) => {
    if (!payload.id) {
        throw Error('Order id should be available at this point');
    }

    draft.summary[payload.id] = {
        ...draft.summary[payload.id],
        ...payload
    };
}));

orderReducer.on(createLogoutAction, (state: OrderReducerState) => produce(state, (draft) => {
    draft.summary = initialState.summary;
    draft.details = initialState.details;
    //draft.raw = initialState.raw;
}));

orderReducer.on(createAssignUsersToOrderAction, (state: OrderReducerState, payload: AssignUserToOrderParams) => produce(state, (draft) => {
    draft.summary[payload.orderId].assignedTo = payload.userNames;
}));

orderReducer.on(createSetExternalDocumentIdAction, (state: OrderReducerState, payload: SetExternalDocumentId) => produce(state, (draft) => {
    let summary = draft.details[payload.orderId].summary;
    if (!summary) return;
    summary.extDocumentId = payload.extDocumentId;
}));


orderReducer.on(createInUseByActiveUserAction, (state: OrderReducerState, payload: string[]) => produce(state, (draft) => {
    draft.inUse = payload.map(p => state.summary[p]).filter((item): item is OrderSummarySorted => !!item)
}));

orderReducer.on(createSetDoneStateAction, (state: OrderReducerState, { orderId, formId, progressState }: ISetDoneState) => produce(state, (draft) => {
    draft.summary[orderId].progressData = draft.summary[orderId].progressData?.map((data) => {
        if (data.formId !== formId) {
            return data;
        }

        return {
            ...data,
            progressState: 'done'
        };
    });

    if (progressState && progressState === 'done') {
        const index = draft.inUse.findIndex(o => o.id?.toString() === orderId);
        draft.inUse.splice(index, 1);
    }

    const allDone = draft.summary[orderId].progressData?.filter(data => data.progressState === 'done').length === draft.summary[orderId].progressData?.length;

    if (!allDone) {
        return;
    }

    draft.summary[orderId].state = OrderSummaryStateEnum.Done;
}));

orderReducer.on(setWorkingOnAction, (state: OrderReducerState, payload: SetWorkingOnParams) => produce(state, (draft) => {
    const workLog = draft.workLog[payload.orderId];

    workLog.worklog?.unshift({
        formId: payload.formId,
        active: true,
        by: payload.userName,
        started: payload.timestamp
    });

    draft.summary[payload.orderId].progressData?.forEach((data) => {
        if (data.formId === payload.formId) {
            data.progressState = 'in-progress';
        }
    });

    if (!draft.inUse.find(o => o.id?.toString() === payload.orderId)) {
        if (state.summary[payload.orderId] !== undefined)
            draft.inUse.unshift(state.summary[payload.orderId]);
    }
}));

orderReducer.on(setStopWorkingOnAction, (state: OrderReducerState, payload: SetStopWorkingOnParams) => produce(state, (draft) => {
    draft.summary[payload.orderId].progressData?.forEach((data) => {
        if (data.formId === payload.formId) {
            data.progressState = payload.status;
        }
    });


    draft.workLog[payload.orderId].worklog?.forEach((data) => {
        if (data.formId === payload.formId && !data.stopped && data.by === payload.userName) {
            data.stopped = payload.stoppedWorkingOn;
            data.active = false;
        }
    });
}));

orderReducer.on(ReceivedFullWorkLogOnOrderAction, (state: OrderReducerState, payload: FullWorkLogOnOrderParams) => produce(state, (draft) => {
    draft.workLog[payload.orderId] = payload;
}));


export default orderReducer;
