import React, { FC } from 'react';
import { Avatar } from 'primereact/avatar';

type Props = {
  objectData: IOrderMeta;
};

export const OrderSearchResult: FC<Props> = ({ objectData }) => {
  return (
    <div className="react-autosuggest__suggestion-link">
      <div className="react-autosuggest__suggestion-image">
          <Avatar icon="pi pi-shopping-cart" size="large" style={{width:"40px", height: "40px"}} />
      </div>
      <div>
        <div className="react-autosuggest__suggestion-type">Order</div>
        <div>
          {objectData.title} {objectData.reference}
        </div>
      </div>
    </div>
  );
};
