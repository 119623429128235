import React, { FC } from 'react';
import { Avatar } from 'primereact/avatar';

type Props = {
  objectData: ICustomerMeta;
};

export const CustomerSearchResult: FC<Props> = ({ objectData }) => {
  return (
    <div className="react-autosuggest__suggestion-link">
      <div className="react-autosuggest__suggestion-image">
          <Avatar icon="pi pi-user" size="large" style={{width:"40px", height: "40px"}} />
      </div>
      <div>
        <div className="react-autosuggest__suggestion-type">Klant</div>
        <div>
          {`${objectData.firstName} ${objectData.lastName} ${objectData.companyName}`}
        </div>
      </div>
    </div>
  );
};
