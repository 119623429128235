import { createReducer } from 'redux-act';
import {
    clearProduct,
    createAddProductElementAction,
    createUpdateProductBuilderSpecAction,
    MoveProductBuilderSpecParams,
    createMoveProductBuilderSpecItem,
    createUpdateProductMetaAction,
    UpdateProductMeta,
    createGetProductsAction,
    createUpdateProductAction,
    createUpdateProductBuilderAction,
    createProductBuilderErrorAction,
    createDeleteProductAction,
    createRemoveProductElementAction,
    RemoveProductParams,
} from '../action/productActionFactory';
import produce from 'immer';
import { ProductSummary, ProductModel, PostProductModelSpecification } from '../../api/product';
import arrayMove from 'array-move';

export interface ProductReducerState {
    builder: ProductModel & { hasError?: boolean },
    byId: {
        [id: number]: ProductSummary;
    }
}

const initialBuilderState: any = {
    name: 'Untitled product',
    version: 1,
    specifications: [],
    hasError: false,
    estProductionPeriod: 49
};

const initialState: ProductReducerState = {
    builder: initialBuilderState,
    byId: {}
};

export const productReducer = createReducer<ProductReducerState>({}, initialState);

productReducer.on(createGetProductsAction, (state: ProductReducerState, payload: ProductSummary[]) => produce(state, (draft) => {
    payload.forEach((productSummary) => {
        if (!productSummary.id) {
            return;
        }

        draft.byId[productSummary.id] = productSummary;
    });
}));

productReducer.on(clearProduct, (state: ProductReducerState) => produce(state, (draft) => {
    draft.builder = initialBuilderState;
}));

productReducer.on(createAddProductElementAction, (state: ProductReducerState, payload: PostProductModelSpecification) => produce(state, (draft) => {
    payload.sequence = draft.builder.specifications.reduce((maxId: number, el: PostProductModelSpecification) => Math.max(el.sequence, maxId), -1) + 1;

    draft.builder.specifications = [
        ...draft.builder.specifications,
        payload
    ];
}));

productReducer.on(createRemoveProductElementAction, (state: ProductReducerState, payload: RemoveProductParams) => produce(state, (draft) => {
    const currentIndex = draft.builder.specifications.findIndex((spec) => spec.sequence === payload.sequence);

    if (currentIndex === -1) {
        return;
    }

    draft.builder.specifications.splice(currentIndex, 1);

    draft.builder.specifications.forEach((spec, index) => {
        spec.sequence = index;
    });
}));

productReducer.on(createUpdateProductBuilderSpecAction, (state: ProductReducerState, payload: PostProductModelSpecification) => produce(state, (draft) => {
    const index = draft.builder.specifications.findIndex((spec) => spec.sequence === payload.sequence);

    if (index === -1) {
        return;
    }

    draft.builder.specifications.splice(index, 1, payload);

}));

productReducer.on(createMoveProductBuilderSpecItem, (state: ProductReducerState, payload: MoveProductBuilderSpecParams) => produce(state, (draft) => {
    const specs = arrayMove(draft.builder.specifications, payload.oldIndex, payload.newIndex);

    draft.builder.specifications = specs;

    draft.builder.specifications.forEach((spec, index) => {
        spec.sequence = index;
    });
}));

productReducer.on(createUpdateProductMetaAction, (state: ProductReducerState, payload: UpdateProductMeta) => produce(state, (draft) => {
    draft.builder.name = payload.name || draft.builder.name;
    draft.builder.description = payload.description || draft.builder.description;
    draft.builder.version = payload.version || draft.builder.version;
    draft.builder.estProductionPeriod = Number((payload.estProductionPeriod || draft.builder.estProductionPeriod) ?? 49)
}));

productReducer.on(createUpdateProductAction, (state: ProductReducerState, payload: number) => produce(state, (draft) => {
    draft.byId[payload] = {
        id: payload,
        name: draft.builder.name,
        version: draft.builder.version,
        description: draft.builder.description,
        estProductionPeriod: draft.builder.estProductionPeriod ?? 49
    };
}));

productReducer.on(createUpdateProductBuilderAction, (state: ProductReducerState, payload: ProductModel) => produce(state, (draft) => {
    draft.builder = payload;
}));

productReducer.on(createProductBuilderErrorAction, (state: ProductReducerState, hasError: boolean) => produce(state, (draft) => {
    draft.builder.hasError = hasError;
}));

productReducer.on(createDeleteProductAction, (state: ProductReducerState, id: number) => produce(state, (draft) => {
    delete draft.byId[id];
}));

export default productReducer;
