import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Island from '../../components/shared/controls/Island/Island';
import { useAuth } from '../../hooks/useAuth';
import { useOnMount } from '../../hooks/useUpdate';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../interfaces/redux/GlobalState';
import { createHash } from "crypto";

const TermsAndConditionsOverview: FC = () => {
  const [t] = useTranslation();

  const { auth, isCustomerUser } = useAuth();
  const settings = useSelector((state: GlobalState) => state.settings);

  const [showAVTypes, setShowAvTypes] = useState({
    b2c: false,
    b2b: false,
    loading: true,
  });

  const md5path : (key:string) => string = (key) => createHash("MD5").update(key).digest("hex").toLowerCase().substring(2,8);
  
  useOnMount(() => {
    if (!isCustomerUser()) {
      setShowAvTypes({ b2b: true, b2c: true, loading: false });
    } else {
      // ophalen customer type
      setShowAvTypes({ b2b: true, b2c: true, loading: false });
    }
  }, [auth]);

  return (
    <>
      <Island>
        <h1>{t('common:mainmenu.termsandconditions')}</h1>
      </Island>
      <Island>
        {showAVTypes.loading && <>Loading...</>}
        {!showAVTypes.loading && (
          <>
            <ul>
              {showAVTypes.b2b &&
                settings.avDocuments &&
                settings.avDocuments
                  .filter((av) => av.avCategory === 'B2B')
                  .map((av) => (
                    <li>
                      <a
                        href={`${process.env.PUBLIC_URL}/av-documenten/${md5path(auth.user['custom:tenant_id'])}/${av.fileName}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {av.documentName}
                      </a>
                    </li>
                  ))}
              {showAVTypes.b2c &&
                settings.avDocuments &&
                settings.avDocuments
                  .filter((av) => av.avCategory === 'B2C')
                  .map((av) => (
                    <li>
                      <a
                        href={`${process.env.PUBLIC_URL}/av-documenten/${md5path(auth.user['custom:tenant_id'])}/${av.fileName}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {av.documentName}
                      </a>
                    </li>
                  ))}
            </ul>
          </>
        )}
      </Island>
    </>
  );
};

export default TermsAndConditionsOverview;
