import { createAction } from 'redux-act';
import { FormDetails, FormSummary } from '../../api/forms/api';
import { FormField, FormMetaData, ISortable } from '../../components/shared/formbuilder/utils/interfaces';

export interface FormId {
    formId: string;
}

export const clearForm = createAction('CLEAR_FORM');
export const addFormElement = createAction('ADD_FORM_ELEMENT', (elementData) => ({ elementData }));
export const deleteFormElement = createAction('DELETE_FORM_ELEMENT', (elementId) => ({ elementId }));
export const editFormElement = createAction('EDIT_FORM_ELEMENT', (elementId: string, elementData: object) => ({ elementId, elementData }));
export const updateFormMeta = createAction('UPDATE_FORM_META', (metaData) => ({ metaData }));
export const updateFormElementOrder = createAction('UPDATE_FORM_ELEMENT_ORDER', (elements) => ({ elements }));
export const loadSavedForm = createAction("LOAD_SAVED_FORM", (formData) => ({ formData }));

export const createGetFormsAction = createAction<FormSummary[]>('Get forms');

export const createSetFormBuilderAction = createAction<FormDetails>('Set form builder');
export const createDeleteFormAction = createAction<FormId>('Delete form')
export const createAddFormSummaryAction = createAction<FormSummary>('Add form summary');
export const createResetFormBuilderAction = createAction('Reset form builder');
export const createAddFormBuilderSpecAction = createAction<FormField>('Add form builder spec');
export const createUpdateFormSummaryAction = createAction<FormId>('Update form summary');
export const createUpdateFormBuilderSpecAction = createAction<FormField>('Update form builder spec');
export const createMoveFormBuilderSpecFieldAction = createAction<ISortable>('Move form builder spec field');
export const createDeleteFormBuilderSpecFieldAction = createAction<string>('Delete form builder spec field');
export const createUpdateFormBuilderSpecMetaDataAction = createAction<FormMetaData>('Update form builder spec meta data');
