import React, { ReactNode } from 'react';
import { useField } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

type Props = {
  label?: string | ReactNode;
  name: string;
  fieldId?:string;
  className?: string;
  isRequired?: boolean;
  boldLabel?: boolean;
  viewMode?: 'FULL' | 'SUMMARY';
};

const FormGroup: React.FC<Props> = ({
  label,
  name,
  fieldId,
  children,
  className,
  isRequired,
  boldLabel,
  viewMode,
}) => {
  const [,meta] = useField(name);
  const { t } = useTranslation();

  const showError: boolean = meta.error !== undefined;
  const formGroupClassName = classnames(
    'form-group',
    {
      'has-error': showError,
      'form-group-bold-label': boldLabel,
    },
    className,
  );

  function getError() {
    if (!meta.error) {
      return null;
    }

    return autotranslate(meta.error);
  }

  
  function autotranslate(errMessage: any): string {
    let err: string = '';
    if (typeof errMessage == 'string') err = errMessage;
    else if (typeof errMessage == 'object') {
      if (errMessage.indexKey) err = errMessage.indexKey;
    }
    if (err.match(/required|at least/i)) {
      return t('common:validation.fieldrequired', {
        field: label,
      });
    }
    return err;
  }

  if (viewMode === 'SUMMARY') {
    return (
      <div className={formGroupClassName}>
        <span className="form-label">
          {label}
          {isRequired ? '*' : ''}
        </span>
        {children}

        {showError === true && (
          <div className="form-error-message">{getError()}</div>
        )}
      </div>
    );
  }

  return (
    <div className={formGroupClassName}>
      {label && (
        <label htmlFor={fieldId} className="form-label">
          {label}
          {isRequired && ' *'}
        </label>
      )}
      {children}

      {showError === true && (
        <div className="form-error-message">{getError()}</div>
      )}
    </div>
  );
};

export default FormGroup;
