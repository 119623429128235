import Toastify from 'toastify-js';

export function showNotification(
    message: string,
    type = 'success',
    options = {}
) {
    Toastify({
        text: message,
        duration: 5000,
        className: `notification notification-${type}`,
        gravity: 'top',
        position: 'center',
        close: true,
        ...options,
    }).showToast();
}


export function showErrorNotification(message: string) {
    showNotification(message, 'danger');
}

export function showWarningNotification(message: string) {
    showNotification(message, 'warning');
}
