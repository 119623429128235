import { createReducer } from 'redux-act';
import produce from 'immer';
import { UserData } from '../../api/user';
import { createFetchUsersAction, createRemoveUserAction } from '../action/userActionFactory';

export interface UserReducerState {
    byId: {
        [userName: string]: UserData;
    }
}

const initialState: UserReducerState = {
    byId: {}
};

const userReducer = createReducer<UserReducerState>({}, initialState);

userReducer.on(createFetchUsersAction, (state: UserReducerState, payload: UserData[]) => produce(state, (draft) => {
    payload.forEach((user) => {
        draft.byId[user.userName] = user;
    });
}));

userReducer.on(createRemoveUserAction, (state: UserReducerState, payload: string) => produce(state, (draft) => {
    if (!draft.byId[payload]) {
        return;
    }
    
    delete draft.byId[payload];
}));

export default userReducer;
   