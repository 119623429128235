import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const ucFirstFormatter: any = {
  type: 'postProcessor',
  name: 'ucfirst',
  process: function (value: any) {
    if (value.length > 1) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  },
};

(async ()=>{
try {
  await i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(ucFirstFormatter)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common', 'statusmessage'],
    defaultNS: 'common',
    load: 'languageOnly',
    fallbackLng: 'nl',
    compatibilityJSON: 'v3',
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    postProcess: ['ucfirst']
  });
}
catch {}
})()


export default i18n;
