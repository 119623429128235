import {
    Approval,
    FullTimeLog,
    OrderDetails,
    OrderSummary,
    OrderSummaryStateEnum
    } from '../../api/order';
import { createAction } from 'redux-act';
import { FormImage } from '../../hooks/useOrder';
import { ProductBuilderFormValues } from '../../hooks/useProduct';

export interface SetFormValuesAction {
    id: string;
    values: ProductBuilderFormValues
}

export interface ApproveOrderPart {
    orderId: string;
    formId: string;
    approval: Approval;
}

export interface SetOrderState {
    orderId: string;
    state: OrderSummaryStateEnum;
}

export interface AssignUserToOrderParams {
    orderId: string;
    userNames: string[];
}

export interface SetExternalDocumentId {
    orderId: string;
    extDocumentId: string | undefined;
}

export interface ISetDoneState {
    orderId: string;
    formId: string;
    progressState?: string;
}

export interface SetStopWorkingOnParams {
    orderId: string;
    formId: string;
    stoppedWorkingOn: string;
    status?: string;
    userName?: string;
}

export interface SetWorkingOnTimeParams {
    orderId: string;
    time: number;
}

export interface SetWorkingOnParams {
    orderId: string;
    formId: string;
    timestamp: string;
    userName: string;
}

export type FullWorkLogOnOrderParams = {
    orderId: string;
} & FullTimeLog;

export interface GetFormImagesAction {
    orderId: string;
    formId: string;
    images: FormImage[]
}

export interface DeleteFormImageAction {
    image: FormImage;
}

export interface FetchOrdersSummaryActionRequest {
    orderFilter?: string;
    customerId?: number;
    pageSize?: number;
    page?: number;
}


export interface OrderTally {
    open: OrderTallyOpen
    done: number,
    onhold: number,
    pendingpayment: number,
    assigned: OrderTallyAssigned
}

export interface OrderTallyAssigned {
    open: number,
    other: number,
    total: number
}

export interface OrderTallyOpen {
    delivery: number,
    total?: number
    planned: number,
    nearDeadline: number,
    pastDeadline: number,
    aftercare: number,
    totalWorktimeEstimation: number,
    readyForTransport: number
}


export const fetchOrderSummaryAction = createAction<FetchOrdersSummaryActionRequest>('Fetch order summaries');
export const orderSummariesFetchedResult = createAction<OrderSummary[]>('Received order summaries');

export const fetchAssignedToMeOrdersAction = createAction('Fetch orders assigned to user');
export const assignedOrderSummariesFetchedResult = createAction<[OrderSummary[], OrderSummary[]]>('Received assigned and working on order summaries for active user');

export const fetchOrderTallyAction = createAction('Fetch order tally')
export const orderTallyFetchedResult = createAction<OrderTally>('Order tally data received')

export const createSetSummaryAction = createAction<OrderSummary>('Set order summary');
export const createSetDetailsAction = createAction<OrderDetails>('Set order details');
export const createSetFormValuesAction = createAction<SetFormValuesAction>('Set order form values');
export const createApproveOrderPartAction = createAction<ApproveOrderPart>('Approve order part');
export const createSetOrderStateAction = createAction<SetOrderState>('Set order state');
export const createSetDoneStateAction = createAction<ISetDoneState>('Set done state')
export const createAssignUsersToOrderAction = createAction<AssignUserToOrderParams>('Assign user to order');
export const createSetExternalDocumentIdAction = createAction<SetExternalDocumentId>('Set newly created external dcument id');
export const createInUseByActiveUserAction = createAction<string[]>('Get orders in use by active user');

export const setWorkingOnAction = createAction<SetWorkingOnParams>('Set working on');
export const setStopWorkingOnAction = createAction<SetStopWorkingOnParams>('Set stop working on');
export const ReceivedFullWorkLogOnOrderAction = createAction<FullWorkLogOnOrderParams>('Retrieved full work log for order');
