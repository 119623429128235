import * as yuplocales from 'yup-locales';
import AppMenu from './components/shared/controls/AppMenu/AppMenu';
import ChangeLanguageDialog from './components/core/language-dialog/languageDialog';
import classNames from 'classnames';
import countries from 'i18n-iso-countries';
import ErrorBoundary from './components/core/errorBoundary/ErrorBoundary';
import FirstTimeLoggedInModal from './components/core/firstTimeLoggedIn-modal';
import Logo from './components/shared/controls/Logo/Logo';
import LogoutModal from './components/core/authentication/logoutModal';
import primeLng from './primelanguages.json';
import ReactGa from 'react-ga';
import ScrollToTop from './components/shared/ScrollToTop';
import TopNavigation from './components/shared/controls/TopBar/TopBar';
import { ApplicationStateProvider } from './context/ApplicationContext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { createLoginPath } from './routing/appUrlGenerator';
import { CustomerDetails } from './api/customer';
import {
  de,
  enUS,
  es,
  fr,
  nl,
  pl
  } from 'date-fns/locale';
import { setLocale as yupSetLocale } from 'yup';
import { en } from './yup-en';
import { enableAllPlugins } from 'immer';
import { GlobalState } from './interfaces/redux/GlobalState';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { isDesktop } from './utils/breakpoints';
import { Messages } from 'primereact/messages';
import { NotificationContextProvider } from './context/NotificationContext';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { resetAllWorklistActivePagesAction } from './redux/action/appStateActionFactory';
import { TenantContextProvider } from './context/TenantContext';
import { useAuth } from './hooks/useAuth';
import { useCustomers } from './hooks/useCustomers';
import { useDispatch, useSelector } from 'react-redux';
import { useForms } from './hooks/useForms';
import { useOnMount } from './hooks/useUpdate';
import { useProduct } from './hooks/useProduct';
import { useStock } from './hooks/useStock';
import { useTheme } from './hooks/useTheme';
import { useTranslation } from 'react-i18next';
import { useUser } from './hooks/useUser';
import {
  useEffect,
  SyntheticEvent,
  useState,
  FC,
  useRef,
  StrictMode,
} from 'react';

// Asset
import {
  fetchLoginState,
  createPathnameAction,
  refreshTokenAction,
} from './redux/action/authActionFactory';

import {
  locale as primeLocale,
  addLocale as primeAddLocale,
} from 'primereact/api';
import { scopeversion } from './version';

type Props = RouteConfigComponentProps;

const App: FC<Props> = ({ route, history, location }) => {
  enableAllPlugins();

  Object.keys(primeLng).forEach((lng) => primeAddLocale(lng, primeLng[lng]));

  const [state, setState] = useState({
    isPrepared: false,
    isLoggedIn: false,
    requiresNewPassword: false,
    user: {},
    isFetching: false,
    error: undefined,
    jwt: '',
    pathname: null,
    mobileMenuActive: false,
    layoutMode: 'static',
    staticMenuInactive: false,
    overlayMenuActive: false,
    showLanguageDialog: false,
    activeLanguage: 'common',
  });
  useTheme();
  const {
    auth,
    handleLogOut,
    userDataIsLoading,
    isCustomerUser,
    role,
    tenantId,
  } = useAuth();
  const { fetchArticles, fetchCategories } = useStock();
  const { getForms } = useForms();
  const { getProducts } = useProduct();

  const { getCustomers, getCustomerDetails, getCustomerDetailsById } =
    useCustomers();

  const { getUsers } = useUser();
  const { i18n } = useTranslation();

  const appTitle = useSelector((state: GlobalState) => state.settings.appTitle);

  const customer: CustomerDetails = getCustomerDetailsById(
    auth.userDbData?.customerId,
  );

  const globalMessages = useRef<Messages>(null);
  const dispatch = useDispatch();

  // registerLocales();

  useOnMount(() => {
    if (auth.isLoggedIn) {
      ReactGa.initialize('UA-139433902-3');
      ReactGa.set({
        Tenant: tenantId,
        Role: role,
      });
      ReactGa.pageview(location.pathname + location.search);
    }
  }, [location]);

  useOnMount(() => {
    if (process.env.REACT_APP_SERVICE_MODE === 'true')
      globalMessages.current?.show({
        severity: 'info',
        detail:
          'The system is currently undergoing maintenance and might be less available',
        sticky: true,
      });
    disableWheelEvents();
  });

  useOnMount(() => {
    if (!auth.isLoggedIn) {
      history.replace(createLoginPath(), { nextPathname: location.pathname });
      return;
    }

    if (auth.isLoggedIn && !auth.isFetching) {
      getProducts();
      fetchArticles();
      fetchCategories();
      getForms();
      //getOrderSummary();
      getCustomers();
      getUsers();

      dispatch(resetAllWorklistActivePagesAction());
    }
  }, [auth.isLoggedIn]);

  useOnMount(() => {
    if (!auth.userDbData) return;

    if (isCustomerUser()) {
      getCustomerDetails(String(auth.userDbData.customerId));
    }
  }, [auth.userDbData]);

  useOnMount(() => {
    try {
      require.resolve(`i18n-iso-countries/langs/${i18n.language}.json`);
      countries.registerLocale(
        require(`i18n-iso-countries/langs/${i18n.language}.json`),
      );
      const lowerlocale = i18n.language.substring(0, 2).toLowerCase();
      // setDefaultLocale(i18n.language);
      primeLocale(lowerlocale);

      if (lowerlocale == 'en') {
        yupSetLocale(en);
      } else {
        yupSetLocale(yuplocales[lowerlocale]);
      }
    } catch (error) {
      countries.registerLocale(require(`i18n-iso-countries/langs/nl.json`));
    }
  }, [i18n.language]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      let expirationCheckRefreshInterval = setInterval(() => {
        dispatch(refreshTokenAction());
      }, 300000);

      return () => {
        clearInterval(expirationCheckRefreshInterval);
      };
    }
  }, [auth.isLoggedIn, auth.exp]);

  useOnMount(() => {
    if (auth.pathname === location.pathname) {
      return;
    }

    dispatch(createPathnameAction(location.pathname));
  }, [location.pathname]);

  useOnMount(() => {
    if (auth.isLoggedIn) {
      dispatch(
        fetchLoginState({
          pathname: document.location.pathname,
        }),
      );
    }
  }, [auth.isLoggedIn]);

  useOnMount(() => {
    if (state.mobileMenuActive) {
      document.body.classList.add('body-overflow-hidden');

      return;
    }

    document.body.classList.remove('body-overflow-hidden');
  }, [state.mobileMenuActive]);

  function setAppState(newAppState: {}) {
    setState({
      ...state,
      ...newAppState,
    });
  }

  function onToggleMenu(event: SyntheticEvent): void {
    event.preventDefault();

    if (isDesktop()) {
      if (state.layoutMode === 'overlay') {
        setAppState({
          overlayMenuActive: !state.overlayMenuActive,
        });
      } else if (state.layoutMode === 'static') {
        setAppState({
          staticMenuInactive: !state.staticMenuInactive,
        });
      }

      return;
    }

    setAppState({
      mobileMenuActive: !state.mobileMenuActive,
    });
  }

  function onChangeLanguageButtonClick(): void {
    setAppState({
      showLanguageDialog: true,
    });
  }

  function disableWheelEvents() {
    document.addEventListener('wheel', (evt) => {
      //evt.preventDefault();
      evt.stopImmediatePropagation();
      const numberInput = document.activeElement as HTMLInputElement;
      if (numberInput) {
        if (numberInput.type === 'number' || numberInput.type === 'currency') {
          numberInput.blur();
        }
      }
    });
  }

  function onMenuItemClick(item: any): void {
    if (item.items) {
      return;
    }

    closeMenu();
  }

  // function registerLocales() {
  //   registerLocale('nl', nl);
  //   registerLocale('de', de);
  //   registerLocale('en', enUS);
  //   registerLocale('es', es);
  //   registerLocale('fr', fr);
  //   registerLocale('pl', pl);
  // }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-static': state.layoutMode === 'static',
    'layout-static-sidebar-inactive':
      (state.staticMenuInactive && state.layoutMode === 'static') ||
      userDataIsLoading,
    'layout-overlay-sidebar-active':
      state.overlayMenuActive && state.layoutMode === 'overlay',
    'layout-mobile-sidebar-active': state.mobileMenuActive,
  });

  function closeMenu() {
    if (isDesktop()) {
      return;
    }

    setAppState({
      mobileMenuActive: false,
    });
  }

  return (
    <StrictMode>
      <ApplicationStateProvider>
        <NotificationContextProvider>
          <TenantContextProvider tenantId={auth.user['custom:tenant_id']}>
            <Helmet>
              <title>{appTitle || 'Scope'}</title>
            </Helmet>
            
              <ChangeLanguageDialog
                onHide={() => {
                  setState({
                    ...state,
                    showLanguageDialog: false,
                  });
                }}
                visible={state.showLanguageDialog}
                language={state.activeLanguage}
              />
              <ScrollToTop>
                <div className={wrapperClass}>
                  <div className="loading-indicator-holder w-screen" style={{background: "var(--primary-color)"}}>
                    <div className="loading-indicator"></div>
                  </div>
                  
                  <div className="layout-sidebar layout-sidebar-dark">
                    <Logo onClick={closeMenu} />
                    <AppMenu
                      onMenuItemClick={onMenuItemClick}
                      onLinkClick={closeMenu}
                    />
                  </div>
                  <div className="layout-main">
                    <Messages ref={globalMessages} />
                    <TopNavigation
                      onToggleMenu={onToggleMenu}
                      onChangeLanguageButtonClick={onChangeLanguageButtonClick}
                      onLogoutButtonClick={handleLogOut}
                    />
                    <div className="layout-main-holder">
                      <ConfirmDialog />
                      <ErrorBoundary>
                        {renderRoutes(route?.routes)}
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
                <LogoutModal />
                {customer?.instagramUserName === null && (
                  <FirstTimeLoggedInModal />
                )}
              </ScrollToTop>
            <div style={{display:"none"}}>
              Scope Build Version: {scopeversion}
            </div>
          </TenantContextProvider>
        </NotificationContextProvider>
      </ApplicationStateProvider>
    </StrictMode>
  );
};
const hotApp = hot(App);

export default hotApp;
