import { ReactComponent as SvgLogo } from '../../assets/img/scope-new.svg';

const LoadingSplash = () => {
  return (
      <div className="h-full p-0 m-0 flex flex-column align-items-center justify-content-center">
        <div className="w-8 sm:w-3">
          <SvgLogo
            id="svglogo"
            className="scope-loading-logo"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
  );
};

export default LoadingSplash;
export { LoadingSplash };
