import { useDispatch, useSelector } from 'react-redux';
import { CustomerApi, CustomerDetails } from '../api/customer';
import { Configuration } from '../api/shared/configuration';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { createFetchCustomersAction, createSetCustomerDetailsAction } from '../redux/action/customerActionFactory';

export function useCustomers() {
    const dispatch = useDispatch();
    const auth = useSelector((state: GlobalState) => state.auth);
    const customer = useSelector((state: GlobalState) => state.customer);
    const apiOptions = new Configuration({
        basePath: process.env.REACT_APP_SERVICE_URL_CUSTOMER,
        accessToken: auth?.jwt,
    });

    const customerApi = new CustomerApi(apiOptions);

    function getCustomerSummaryById(id: string) {
        return customer.summary[id];
    }

    function getCustomerDetailsById(id: string) {
        return customer.details[id];
    }

    async function getCustomers() {
        try {
            const response = await customerApi.getCustomers();

            dispatch(createFetchCustomersAction(response.data));
        } catch (error) {
        }
    }

    async function getCustomerDetails(id?: string) {
        try {
            if (id === undefined) return
            const response = await customerApi.getCustomerById(id);

            dispatch(createSetCustomerDetailsAction(response.data));

            return response;
        } catch (error) {
        }
    }

    async function updateCustomer(customer: CustomerDetails) {
        try {
            const response = await customerApi.putCustomer(customer);

            return response;
        } catch (error) {
        }
    }

    return {
        customer,
        getCustomerSummaryById,
        getCustomerDetailsById,
        customerApi,
        getCustomers,
        getCustomerDetails,
        updateCustomer
    };
}
