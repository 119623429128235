import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { Configuration, CustomerApi } from "../../../api/customer";
import { useAuth } from "../../../hooks/useAuth";
import { showNotification } from "../../../utils/notification";
import Button from "../../shared/controls/Button/Button";
import InternalModal from "../../shared/controls/Modal";
import InputText from "../../shared/formbuilder/elements/InputText";
import { useTenantContext } from "../../../context/TenantContext";

const FirstTimeLoggedInModal: FC = () => {

    const { auth } = useAuth();
    const [isOpen, setisOpen] = useState(true);

    const tenantContext = useTenantContext();

    const handleSubmit = async (values: any) => {
        let customerApi = new CustomerApi(
            new Configuration({
                basePath: process.env.REACT_APP_SERVICE_URL_CUSTOMER,
                accessToken: auth?.jwt,
            }),
        );

        await customerApi.putInstagram({
            instagram: values.instagramHandle
        });

        showNotification("We hebben je voorkeur opgeslagen");
        setisOpen(false);
    };


    if (tenantContext?.applicationSettings.showInstagramTagPopup !== true || !tenantContext?.applicationSettings.tenantInstagramId) {
        return <></>
    }

    // if (!tenantContext?.tenantId?.startsWith('T_WeijBV_')) {
    //     return <></>
    // }

    return (
        <InternalModal title={"Taggen op instagram"} isOpen={isOpen} onClose={() => setisOpen(false)} isSmall>
            <Formik
                initialValues={{}}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>

                        <div className="grid">
                            <div className="table-list col-12">
                                <Button isLink icon={"user-plus"}>
                                    <a href={`https://www.instagram.com/${tenantContext.applicationSettings.tenantInstagramId}`} target="_blank" rel="noreferrer">Volg ons op Instagram</a>
                                </Button>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="table-list col-12">
                                Zou je het leuk vinden om door ons getagd te worden op social media? Voer dan je instagram gebruikersnaam (bv. @weijbv) in:<br /><br />

                                <div className="table-list-row">
                                    <div className="table-list-heading">
                                        Gebruikersnaam op instagram:
                                    </div>
                                    <div className="table-list-cell">
                                        <InputText name="instagramHandle" placeholder="@" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Button buttonType="submit" icon={"check"}>
                            Opslaan
                        </Button> <Button onClick={() => handleSubmit({ instagramHandle: "" })} isGhost icon={"times"}>
                            Ik heb geen interesse / ik heb geen instagram
                        </Button>
                    </Form>
                )
                }
            </Formik>
        </InternalModal >
    )
};

export default FirstTimeLoggedInModal;