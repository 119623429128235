import { createReducer } from 'redux-act';
import { produce } from 'immer'
import {
  failedFetchingLoginState,
  fetchUser,
  failedFetchingUser,
  newPasswordRequired,
  loggedIn,
  createLogoutAction,
  createPathnameAction,
  passwordChangedSuccesfully,
  startForgottenPassword,
  submitNewPassword,
  cancelForgottenPassword,
  createSetNewTokenAction,
  INewToken,
  tokenRefreshedAction,
  IRefreshTokenResult,
  clearAuthError,
  loggedOut,
} from '../action/authActionFactory';
import { getTenantConfiguration } from '../../utils/tenantConfiguration';
import { createLoginPath } from '../../routing/appUrlGenerator';
import storage from 'localforage';

export interface AuthReducerState {
  isPrepared: boolean;
  isPasswordForgotten: boolean;
  isLoggedIn: boolean;
  requiresNewPassword: boolean;
  user: {
    sub: string;
    'custom:tier': string;
    'custom:tenant_id': string;
    given_name: string;
    family_name: string;
    'custom:role': string;
    email: string;
    username?: string;
    customerId?: number;
  };
  userDbData: any | null;
  isFetching: boolean;
  error?: string;
  jwt: string;
  pathname: string;
  exp: number | null;
  data?: any;
}

const initial: AuthReducerState = {
  isPrepared: false,
  isPasswordForgotten: false,
  isLoggedIn: false,
  requiresNewPassword: false,
  user: {
    sub: '',
    'custom:tier': '',
    'custom:tenant_id': getTenantConfiguration()
      ? getTenantConfiguration().id
      : '',
    given_name: '',
    family_name: '',
    'custom:role': '',
    email: '',
    customerId: undefined
  },
  userDbData: null,
  isFetching: false,
  error: undefined,
  jwt: '',
  pathname: createLoginPath(),
  exp: null,
};

const authReducer = createReducer<AuthReducerState>({}, initial)

authReducer.on(failedFetchingLoginState, (state: AuthReducerState, payload: { pathname: string }) => produce(state, (draft) => {
  draft.isLoggedIn = false
  draft.requiresNewPassword = false
  draft.userDbData = null
  draft.isFetching = false
  draft.user = {
    sub: '',
    'custom:tier': '',
    'custom:tenant_id': getTenantConfiguration()
      ? getTenantConfiguration().id
      : '',
    given_name: '',
    family_name: '',
    'custom:role': '',
    email: '',
    customerId: undefined
  }
  draft.jwt = ''
  draft.pathname = createLoginPath()
  draft.isPrepared = true
}))

authReducer.on(clearAuthError, (state: AuthReducerState) => produce(state, (draft) => {
  draft.error = undefined
}))

authReducer.on(fetchUser, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.isFetching = true
  draft.requiresNewPassword = false
  draft.error = undefined
  draft.data = undefined
  draft.userDbData = {
    ...state.userDbData,
    userName: payload.email
  }
}))

authReducer.on(startForgottenPassword, (state: AuthReducerState) => produce(state, (draft) => { draft.isPasswordForgotten = true }))
authReducer.on(cancelForgottenPassword, (state: AuthReducerState) => produce(state, (draft) => { draft.isPasswordForgotten = false }))

authReducer.on(failedFetchingUser, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.isFetching = false
  draft.error = payload
  draft.data = undefined
}))

authReducer.on(newPasswordRequired, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.isFetching = false
  draft.error = undefined
  draft.requiresNewPassword = true
  draft.data = payload.data
  draft.user = payload.user || state.user
}))

authReducer.on(loggedIn, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.isPrepared = true
  draft.isLoggedIn = true
  draft.requiresNewPassword = payload.state === 'newPasswordRequired'
  draft.user = payload.user || state.user
  draft.userDbData = payload.userDbData || state.userDbData
  draft.isFetching = false
  draft.error = undefined
  draft.jwt = payload.jwt
  draft.exp = payload.exp || state.exp
  draft.pathname = payload.pathname
  draft.data = undefined
}))

authReducer.on(loggedOut, (state: AuthReducerState) => produce(state, (draft) => {
  draft.isPrepared = false
  draft.isPasswordForgotten = false
  draft.isLoggedIn = false
  draft.requiresNewPassword = false
  draft.user = {
    sub: '',
    'custom:tier': '',
    'custom:tenant_id': getTenantConfiguration()
      ? getTenantConfiguration().id
      : '',
    given_name: '',
    family_name: '',
    'custom:role': '',
    email: '',
    customerId: undefined
  }
  draft.userDbData = null
  draft.isFetching = false
  draft.error = undefined
  draft.jwt = ''
  draft.pathname = createLoginPath()
  draft.exp = null
  storage.clear()
  location.replace('/');
}))


authReducer.on(createPathnameAction, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.pathname = payload
}))
authReducer.on(passwordChangedSuccesfully, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.isPasswordForgotten = true
}))

authReducer.on(submitNewPassword, (state: AuthReducerState, payload: any) => produce(state, (draft) => {
  draft.requiresNewPassword = false
  draft.isLoggedIn = false
}))

authReducer.on(createSetNewTokenAction, (state: AuthReducerState, payload: INewToken) => produce(state, (draft) => { draft.exp = payload.exp }))
authReducer.on(tokenRefreshedAction, (state: AuthReducerState, payload: IRefreshTokenResult) => produce(state, (draft) => {
  draft.exp = payload.exp;
  draft.jwt = payload.jwt;
}))


export const authReducerOld = createReducer(
  {
    [failedFetchingLoginState as any]: (state, payload) => {
      return Object.assign({}, initial, {
        isPrepared: true,
        pathname: payload.pathname,
      });
    },
    [fetchUser as any]: (state, payload) => {
      return Object.assign({}, state, {
        isFetching: true,
        requiresNewPassword: false,
        error: undefined,
        data: undefined,
        userDbData: {
          ...state.userDbData,
          userName: payload.email,
        },
      });
    },
    [startForgottenPassword as any]: (state, payload) => {
      return Object.assign({}, state, {
        isPasswordForgotten: true,
      });
    },
    [cancelForgottenPassword as any]: (state, payload) => {
      return Object.assign({}, state, {
        isPasswordForgotten: false,
      });
    },
    [failedFetchingUser as any]: (state, err) =>
      Object.assign({}, state, {
        isFetching: false,
        error: err,
        data: undefined,
      }),
    [newPasswordRequired as any]: (state, payload) =>
      Object.assign({}, state, {
        isFetching: false,
        error: undefined,
        requiresNewPassword: true,
        data: payload.data,
        user: payload.user || state.user,
      }),
    [loggedIn as any]: (state, payload) => {
      return Object.assign({}, state, {
        isPrepared: true,
        isLoggedIn: true,
        requiresNewPassword: payload.state === 'newPasswordRequired',
        user: payload.user || state.user,
        userDbData: payload.userDbData || state.userDbData,
        isFetching: false,
        error: undefined,
        jwt: payload.jwt,
        exp: payload.exp || state.exp,
        pathname: payload.pathname,
        data: undefined,
      });
    },
    [createLogoutAction as any]: () =>
      Object.assign({}, initial, {
        isPrepared: true,
      }),
    [createPathnameAction as any]: (state, payload) =>
      Object.assign({}, state, {
        pathname: payload,
      }),
    [passwordChangedSuccesfully as any]: (state, payload) =>
      Object.assign({}, state, {
        isPasswordForgotten: payload,
      }),
    [submitNewPassword as any]: (state, payload) =>
      Object.assign({}, state, {
        requiresNewPassword: false,
        isLoggedIn: false,
      }),
    [createSetNewTokenAction as any]: (state, payload: INewToken) =>
      Object.assign({}, state, {
        exp: payload.exp,
      }),
    [tokenRefreshedAction as any]: (state, payload: IRefreshTokenResult) => {
      return Object.assign({}, state, {
        exp: payload.exp,
        jwt: payload.jwt,

      })
    }
  },
  initial,
);

export default authReducer;
