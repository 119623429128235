import { createAction } from 'redux-act';

export interface INewToken {
  exp: number;
}

export interface IRefreshTokenResult {
  exp: number;
  iat: number;
  auth_time: number;
  jwt: string;
}


export const fetchLoginState = createAction<{
  pathname: string;
  cognito?: any;
}>('fetch login state');
export const failedFetchingLoginState = createAction<any>(
  'failed fetching login state',
);

export const fetchUser = createAction<{}>('fetch user information');
export const failedFetchingUser = createAction<{}>('failed fetching user information');

export const startForgottenPassword = createAction('Show forgot password');
export const cancelForgottenPassword = createAction('Cancel forgot password');

export const loggedIn = createAction<{}>('logged in');
export const createLogoutAction = createAction('click logout');
export const loggedOut = createAction('logout completed');

export const clearAuthError = createAction('Clearing auth error');

export const createPathnameAction = createAction<string>(
  'Set current pathname',
);

export const newPasswordRequired = createAction<{}>(
  'request required new password',
);
export const submitNewPassword = createAction<{
  user: any;
  email: string;
  password: string;
}>('Submit new password');

export const passwordChangedSuccesfully = createAction<boolean>(
  'Set is password forgotten',
);

export const refreshTokenAction = createAction("Request refresh of token");
export const tokenRefreshedAction = createAction<IRefreshTokenResult>("Token refresh response");


export const createSetNewTokenAction = createAction<INewToken>('Update expiration');
