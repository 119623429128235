import { takeLatest, call, put, select } from 'redux-saga/effects';
import { fetchOrderSummaryAction, FetchOrdersSummaryActionRequest, orderSummariesFetchedResult, assignedOrderSummariesFetchedResult, fetchAssignedToMeOrdersAction, OrderTally, orderTallyFetchedResult, fetchOrderTallyAction } from "../action/orderActionFactory"

import { OrderApi, Configuration, OrderSummary, OrderStats } from "../../api/order";
import { Action } from 'redux-act';
import { GlobalState } from '../../interfaces/redux/GlobalState';
import { AxiosResponse } from 'axios';
import { AuthReducerState } from '../reducer/authReducerFactory';

export function* fetchOrderSummary(params: Action<FetchOrdersSummaryActionRequest>) {

    const auth: AuthReducerState = yield select((state: GlobalState) => state.auth)

    if (!auth || !auth.jwt) return

    try {

        const lastUpdated: number | undefined = yield select((state: GlobalState) => state.order.updatedAt)

        if (lastUpdated !== undefined && lastUpdated + 30000 > Date.now()) return

        const orderApi = new OrderApi(new Configuration({
            basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
            accessToken: auth?.jwt,
        }));

        let orders: AxiosResponse<OrderSummary[]>;

        if (params.payload.customerId !== undefined)
            orders = yield call([orderApi, orderApi.getOrdersCustomerid], params.payload.customerId.toString())
        else
            orders = yield call([orderApi, orderApi.getOrders], params.payload.orderFilter || 'all', params.payload.pageSize, params.payload.page)

        let data = orders.data;

        // Postprocess

        // step: customers can only ever see their own orders, so throw away all others
        if (auth.user?.['custom:role'] === 'Customer') {
            data = data.filter(d => d.customerId === auth.user?.customerId)
        }

        yield put(orderSummariesFetchedResult(data || []))
    }
    catch { }

    return
}

export function* getAssignedOrders() {

    const auth: AuthReducerState = yield select((state: GlobalState) => state.auth)
    if (!auth || !auth.jwt) return

    // quick leave: Customers can't get assigned orders
    if (auth.user?.['custom:role'] === 'Customer')
        return

    try {
        const orderApi = new OrderApi(new Configuration({
            basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
            accessToken: auth?.jwt,
        }));

        let orders: AxiosResponse<OrderSummary[]> = yield call([orderApi, orderApi.getOrdersInUseByActiveUser])

        let inUse: OrderSummary[] = []
        let assigned: OrderSummary[] = []

        orders.data?.forEach(o => {
            if (o.assignedTo !== undefined && o.assignedTo.includes(auth.userDbData.userName!!)) {
                if (assigned.findIndex(f=>f.id == o.id) == -1)
                    assigned.push(o)
            }
            if (o.workedOnBy !== undefined && o.workedOnBy.filter(i => i.indexOf(auth.userDbData.userName!!) !== -1).length > 0) {
                if (inUse.findIndex(f=>f.id == o.id) == -1) 
                    inUse.push(o)
            }
        });

        yield put(assignedOrderSummariesFetchedResult([inUse, assigned]))
    }
    catch { }

    return
}

export function* getOrderTally() {
    const auth: AuthReducerState = yield select((state: GlobalState) => state.auth)
    if (!auth || !auth.jwt) return

    try {
        const current: any = yield select((state: GlobalState) => state.order.tally)

        if (current.updatedAt !== undefined && current.updatedAt + 10000 > Date.now()) {
            return
        }

        const orderApi = new OrderApi(new Configuration({
            basePath: process.env.REACT_APP_SERVICE_URL_ORDER,
            accessToken: auth?.jwt,
        }));

        let orderTally: AxiosResponse<OrderStats> = yield call([orderApi, orderApi.getOrdersStats])

        let mappedTally: OrderTally = {
            open: {
                aftercare: orderTally.data.open?.aftercare ?? -1,
                nearDeadline: orderTally.data.open?.nearDeadline ?? -1,
                pastDeadline: orderTally.data.open?.pastDeadline ?? -1,
                planned: orderTally.data.open?.planned ?? -1,
                total: orderTally.data.open?.total,
                totalWorktimeEstimation: orderTally.data.open?.totalWorktimeEstimation ?? -1,
                delivery: orderTally.data.open?.delivery ?? -1,
                readyForTransport: orderTally.data.open?.readyForTransport ?? -1
            },
            done: orderTally.data.done ?? -1,
            assigned: {
                open: orderTally.data.assigned?.open ?? 0,
                other: orderTally.data.assigned?.other ?? 0,
                total: orderTally.data.assigned?.total ?? 0,
            },
            onhold: orderTally.data.onhold ?? -1,
            pendingpayment: orderTally.data.pendingpayment ?? -1
        }

        yield put(orderTallyFetchedResult(mappedTally))

    }
    catch { }

    return
}

export function* orderSaga() {
    yield takeLatest(fetchOrderSummaryAction, fetchOrderSummary)
    yield takeLatest(fetchAssignedToMeOrdersAction, getAssignedOrders)
    yield takeLatest(fetchOrderTallyAction, getOrderTally)
}