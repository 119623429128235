import React, { FC, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { AiOutlineLoading3Quarters } from '@react-icons/all-files/ai/AiOutlineLoading3Quarters';
import { Tooltip } from 'primereact/tooltip';

type Props = {
  type?: string;
  className?: string;
  to?: string;
  onClick?: Function;
  icon?: string | JSX.Element;
  buttonType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  isGhost?: boolean;
  isLink?: boolean;
  isSmall?: boolean;
  iconRight?: boolean;
  isLoading?: boolean;
  iconOnly?: boolean;
  title?: string;
};

const Button: FC<Props & { [key: string]: any }> = ({
  type,
  className,
  to,
  onClick,
  icon,
  children,
  buttonType,
  disabled,
  isGhost,
  isLink,
  isSmall,
  iconRight,
  isLoading,
  iconOnly,
  title,
  ...others
}) => {
  const history = useHistory();
  const buttonClassName = classnames(
    'button',
    {
      'button-primary': type === 'primary',
      'button-danger': type === 'danger',
      'button-ghost': isGhost,
      'button-link': isLink,
      'button-small': isSmall,
      'button-icon-right': iconRight,
      'button--loading': isLoading,
      'button--icon-only': iconOnly,
    },
    className,
    {
      'button-with-tooltip': title !== undefined,
    },
  );

  function renderIcon() {
    if (isLoading) {
      return <AiOutlineLoading3Quarters />;
    }

    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') return <span className={`pi pi-${icon}`} />;
    else return icon;
  }

  function handleClick(event: SyntheticEvent) {
    if (!onClick || isLoading) {
      return;
    }

    if (document.activeElement && document.activeElement !== document.body) {
      // @ts-ignore
      document.activeElement.blur();
    }

    onClick(event);
  }

  if (to) {
    return (
      <>
        <Tooltip target=".button-with-tooltip" position='bottom'  />
        <button
          {...others}
          type={buttonType}
          className={buttonClassName}
          onClick={(e) => {
            e.preventDefault();

            history.push(to);
          }}
          disabled={disabled}
          data-pr-tooltip={title}
        >
          {renderIcon()}
          {children}
        </button>
      </>
    );
  }

  return (
    <>
      <Tooltip target=".button-with-tooltip" autoHide />

      <button
        {...others}
        type={buttonType}
        className={buttonClassName}
        onClick={handleClick}
        disabled={disabled}
        data-pr-tooltip={title}
      >
        {iconRight ? (
          <>
            {children}
            {renderIcon()}
          </>
        ) : (
          <>
            {renderIcon()}
            {children}
          </>
        )}
      </button>
    </>
  );
};

Button.defaultProps = {
  type: 'primary',
  buttonType: 'button',
};

export default Button;
